import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { CloseOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';

import { QrScanner } from '@yudiel/react-qr-scanner';
import Swal from 'sweetalert2';
import { checkInToHow } from './../api';
import AdminLayout from './../components/layouts/AdminLayout/AdminLayout';

import './../App.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CheckIn = () => {
  const [cameraActive, setCameraActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const delay = 5000;
  const result = 'No result';
  const previewStyle = {
    height: 450,
    width: 450,
  };

  useEffect(() => {
    return () => {
      if (cameraActive) {
        // ลอง set ให้ cameraActive เป็น false ตอน component ถูก unmount
        setCameraActive(false);
      }
    };
  }, [cameraActive]);

  const toggleCamera = () => {
    setCameraActive((prevState) => !prevState);
  };

  const handleScan = async (userId) => {
    if (isProcessing) {
      return;
    }

    console.log('data ==>', userId);
    console.log('state ==>', cameraActive);

    if (userId && setCameraActive) {
      setIsProcessing(true);

      try {
        await checkInToHow(userId);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Checked in successfully!',
        });
      } catch (error) {
        if (error.response) {
          console.error('Data:', error.response.data);
          console.error('Status:', error.response.status);
          console.error('Headers:', error.response.headers);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          });
          console.error('Error Message:', error.message);
        }
      }

      setIsProcessing(false);
      setCameraActive(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3 text-center">Check-In</h1>
        <div className="row">
          <div className="col-12 text-center">
            {!cameraActive ? (
              <img
                src="/assets/images/ipad-camera.png"
                alt="qr-code"
                height={350}
                onClick={() => setCameraActive(true)}
              />
            ) : (
              <div className="align-center" align="center">
                <div style={{ width: '350px', marginBottom: '10px' }}>
                  <QrScanner
                    constraints={{
                      facingMode: {
                        exact: 'environment',
                      },
                    }}
                    scanDelay={delay}
                    onDecode={(result) => handleScan(result)}
                    onError={(error) => console.log(error?.message)}
                  />

                  <br />
                  <div className="text-center">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => setCameraActive(false)}
                      icon={<CloseOutlined />}
                      size={'large'}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CheckIn;
