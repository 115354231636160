import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FormOutlined, StopOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import ContentEvent from "../../components/meetingRoom/ContentEvent";
import { useQuery } from "@tanstack/react-query";
import { updateBookingRoomStatus } from "../../api";
import { EditIcon, CancelIcon, CloseIcon } from "../../components/AppIcon";

const ModalDetailEvent = ({
  isModalBookingDetail,
  closeDetail,
  eventDetail,
  setIsModalNote,
  booking,
  handleDeleteBooking,
}) => {
  const id = eventDetail.id;

  const delEvent = () => {
    const customSwal = Swal.mixin({
      customClass: {
        confirmButton: " btnSweetDanger",
        cancelButton: "btnSweetBlack",
      },
      buttonsStyling: false,
    });
    customSwal
      .fire({
        title: "Do you want to cancel meeting room?",
        text: "If you press cancel, we will send an email to notify the owner of this reservation.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          //ยิง api ลบ event , get router push หน้าเดิมใหม่ หรือ get ข้อมูล event มาใหม่ก็ได้
          try {
            handleDeleteBooking();
            const updateResponse = await updateBookingRoomStatus(
              {
                status: "Canceled",
              },
              id
            );
            if (updateResponse.status === 200) {
              Swal.fire({
                title: "Deleted!",
                text: "Your booking has been deleted.",
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              })        
            } else {
              Swal.fire(
                "Error",
                "An error occurred while deleting the item.",
                "error"
              );
            }
          } catch (error) {
            Swal.fire(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          }
          closeDetail();
        }
      });
  };
  const equipments = [
    { label: "TV", value: "tv" },
    { label: "HDMI", value: "hdmi" },
    { label: "Note paper", value: "notepaper" },
    { label: "Pen,Pencil", value: "pen-pencil" },
    { label: "Flip Chart", value: "flipchart" },
    { label: "Candy", value: "candy" },
    { label: "Water", value: "water" },
    { label: "Glass", value: "glass" },
  ];
  return (
    <Modal
      className="timeList"
      open={isModalBookingDetail}
      headerBg={"#eeeeee"}
      width={450}
      onCancel={closeDetail}
      closeIcon={false}
      footer=""
      zIndex={1001}
      title={
        <div className="d-flex align-items-center justify-content-end">
          <div className="controlBox">
            <Link
              to={`/meeting-rooms/edit-booking/${eventDetail?.id}`}
              className="btn btnModal"
            >
              <EditIcon />
            </Link>

            <a className="btn btnModal text-danger" onClick={delEvent}>
              <CancelIcon />
            </a>
            <a className="btn btnModal" onClick={closeDetail}>
              <CloseIcon />
            </a>
          </div>
        </div>
      }
    >
      <ContentEvent
        view="calendar"
        setIsModalNote={setIsModalNote}
        equipments={equipments}
        booking={booking}
        eventDetail={eventDetail}
        isPreview={false}
      />
    </Modal>
  );
};

export default ModalDetailEvent;
