import AxiosInstance from "./axios";


export async function getEvents(currentPage, pageSize) {
  const response = await AxiosInstance.get(
    `/events?page=${currentPage}&limit=${pageSize}`
  );
  return response.data;
}

export async function getEvent(id) {
  const response = await AxiosInstance.get(`/events/${id}`);
  console.log(response.data.statusCode);
  return response;
}

export async function getEventWithAttendees(id) {
  const response = await AxiosInstance.get(`/events/${id}/attendees`);
  console.log(response.data.statusCode);
  return response;
}

export async function getMembersWithoutAttendees(id) {
  const response = await AxiosInstance.get(`/events/${id}/members`);
  console.log(response.data.statusCode);
  return response;
}

export async function createEvent(formData) {
  const response = await AxiosInstance.post(`/events`, formData);

  return response;
}

export async function updateEvent(id, formData) {
  const response = await AxiosInstance.patch(`/events/${id}`, formData);
  return response;
}

export async function deleteEvent(id) {
  const response = await AxiosInstance.delete(`/events/${id}`);
  return response;
}
