import Icon from '@ant-design/icons';

const CancelSvg = () => (
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 23C3.0625 23 2.47417 22.75 1.985 22.2499C1.49583 21.7499 1.25083 21.1481 1.25 20.4444V3.83333H0V1.27778H6.25V0H13.75V1.27778H20V3.83333H18.75V20.4444C18.75 21.1472 18.5054 21.7491 18.0163 22.2499C17.5271 22.7508 16.9383 23.0009 16.25 23H3.75ZM16.25 3.83333H3.75V20.4444H16.25V3.83333ZM6.25 17.8889H8.75V6.38889H6.25V17.8889ZM11.25 17.8889H13.75V6.38889H11.25V17.8889Z" fill="#FF0000"/>
</svg>

);

export const CancelIcon = (props) => (
  <span className="ico" style={{ marginTop: '-15px' }}>
    <Icon component={CancelSvg} {...props} />
  </span>
);

const CloseSvg = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.6013 1.21512C17.475 1.09041 17.325 0.991476 17.1598 0.923972C16.9946 0.856467 16.8176 0.82172 16.6388 0.82172C16.46 0.82172 16.2829 0.856467 16.1178 0.923972C15.9526 0.991476 15.8026 1.09041 15.6763 1.21512L9 7.77974L2.32375 1.20167C2.19735 1.07712 2.04729 0.978332 1.88214 0.91093C1.71698 0.843529 1.53998 0.808838 1.36122 0.808838C1.18246 0.808838 1.00545 0.843529 0.840303 0.91093C0.675152 0.978332 0.525092 1.07712 0.398692 1.20167C0.272291 1.32621 0.172024 1.47406 0.103616 1.63678C0.0352086 1.79951 -1.33185e-09 1.97391 0 2.15004C1.33185e-09 2.32617 0.0352086 2.50057 0.103616 2.66329C0.172024 2.82602 0.272291 2.97387 0.398692 3.09841L7.07495 9.67649L0.398692 16.2546C0.272291 16.3791 0.172024 16.527 0.103616 16.6897C0.0352086 16.8524 0 17.0268 0 17.2029C0 17.3791 0.0352086 17.5535 0.103616 17.7162C0.172024 17.8789 0.272291 18.0268 0.398692 18.1513C0.525092 18.2758 0.675152 18.3746 0.840303 18.442C1.00545 18.5094 1.18246 18.5441 1.36122 18.5441C1.53998 18.5441 1.71698 18.5094 1.88214 18.442C2.04729 18.3746 2.19735 18.2758 2.32375 18.1513L9 11.5732L15.6763 18.1513C15.8027 18.2758 15.9527 18.3746 16.1179 18.442C16.283 18.5094 16.46 18.5441 16.6388 18.5441C16.8175 18.5441 16.9945 18.5094 17.1597 18.442C17.3248 18.3746 17.4749 18.2758 17.6013 18.1513C17.7277 18.0268 17.828 17.8789 17.8964 17.7162C17.9648 17.5535 18 17.3791 18 17.2029C18 17.0268 17.9648 16.8524 17.8964 16.6897C17.828 16.527 17.7277 16.3791 17.6013 16.2546L10.9251 9.67649L17.6013 3.09841C18.1201 2.58723 18.1201 1.7263 17.6013 1.21512Z" fill="black"/>
  </svg>

);

export const CloseIcon = (props) => (
  <span className="ico" style={{ marginTop: '-15px' }}>
    <Icon component={CloseSvg} {...props} />
  </span>
);

const DateSvg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.6444 17.7074H3.49612C1.56877 17.7074 0 16.1386 0 14.2112V5.34175C0 3.4144 1.56877 1.84563 3.49612 1.84563H3.80987V1.23921C3.80987 0.71453 4.23436 0.290039 4.75904 0.290039C5.28373 0.290039 5.70822 0.71453 5.70822 1.23921V1.84563H11.4349V1.23921C11.4349 0.71453 11.8594 0.290039 12.3841 0.290039C12.9087 0.290039 13.3332 0.71453 13.3332 1.23921V1.84563H13.647C15.5743 1.84563 17.1431 3.4144 17.1431 5.34175V14.2139C17.1431 16.1412 15.5743 17.71 13.647 17.71L13.6444 17.7074ZM3.49612 3.74397C2.6155 3.74397 1.89834 4.46112 1.89834 5.34175V14.2139C1.89834 15.0945 2.6155 15.8116 3.49612 15.8116H13.6444C14.525 15.8116 15.2421 15.0945 15.2421 14.2139V5.34175C15.2421 4.46112 14.525 3.74397 13.6444 3.74397H13.3306V4.55868C13.3306 5.08336 12.9061 5.50785 12.3814 5.50785C11.8567 5.50785 11.4323 5.08336 11.4323 4.55868V3.74397H5.70558V4.55868C5.70558 5.08336 5.28109 5.50785 4.75641 5.50785C4.23173 5.50785 3.80724 5.08336 3.80724 4.55868V3.74397H3.49348H3.49612ZM13.0168 8.69022H4.12363C3.59895 8.69022 3.17445 8.26572 3.17445 7.74104C3.17445 7.21636 3.59895 6.79187 4.12363 6.79187H13.0168C13.5415 6.79187 13.966 7.21636 13.966 7.74104C13.966 8.26572 13.5415 8.69022 13.0168 8.69022Z" fill="#77787C"/>
  </svg>
);

export const DateIcon = (props) => (
  <span className="ico">
    <Icon component={DateSvg} {...props} />
  </span>
);

const EditSvg = () => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.8437 12.1872C21.5388 12.1872 21.2463 12.3065 21.0307 12.519C20.8151 12.7314 20.694 13.0195 20.694 13.32V20.1165C20.694 20.4169 20.5729 20.7051 20.3573 20.9175C20.1417 21.1299 19.8492 21.2493 19.5443 21.2493H3.449C3.14409 21.2493 2.85167 21.1299 2.63606 20.9175C2.42046 20.7051 2.29933 20.4169 2.29933 20.1165V4.25787C2.29933 3.95745 2.42046 3.66933 2.63606 3.45689C2.85167 3.24446 3.14409 3.12512 3.449 3.12512H10.347C10.6519 3.12512 10.9443 3.00577 11.1599 2.79334C11.3755 2.5809 11.4967 2.29278 11.4967 1.99236C11.4967 1.69193 11.3755 1.40381 11.1599 1.19137C10.9443 0.978939 10.6519 0.859595 10.347 0.859595H3.449C2.53427 0.859595 1.657 1.21763 1.01019 1.85493C0.363376 2.49223 0 3.3566 0 4.25787V20.1165C0 21.0178 0.363376 21.8822 1.01019 22.5195C1.657 23.1568 2.53427 23.5148 3.449 23.5148H19.5443C20.4591 23.5148 21.3363 23.1568 21.9831 22.5195C22.63 21.8822 22.9933 21.0178 22.9933 20.1165V13.32C22.9933 13.0195 22.8722 12.7314 22.6566 12.519C22.441 12.3065 22.1486 12.1872 21.8437 12.1872ZM4.59867 13.0481V17.851C4.59867 18.1514 4.71979 18.4395 4.9354 18.652C5.151 18.8644 5.44342 18.9838 5.74833 18.9838H10.6229C10.7742 18.9846 10.9242 18.956 11.0643 18.8997C11.2044 18.8433 11.3318 18.7602 11.4392 18.6553L19.3949 10.8052L22.6599 7.65615C22.7677 7.55085 22.8532 7.42557 22.9116 7.28753C22.9699 7.14949 23 7.00143 23 6.85189C23 6.70236 22.9699 6.5543 22.9116 6.41626C22.8532 6.27822 22.7677 6.15294 22.6599 6.04764L17.7853 1.1881C17.6785 1.08192 17.5513 0.997653 17.4112 0.940144C17.2711 0.882636 17.1208 0.853027 16.9691 0.853027C16.8173 0.853027 16.667 0.882636 16.5269 0.940144C16.3868 0.997653 16.2597 1.08192 16.1528 1.1881L12.9108 4.39381L4.93207 12.2438C4.82552 12.3497 4.74122 12.4752 4.684 12.6132C4.62679 12.7512 4.59779 12.899 4.59867 13.0481ZM16.9691 3.58955L20.2226 6.79526L18.5901 8.40378L15.3366 5.19807L16.9691 3.58955ZM6.898 13.5125L13.7155 6.79526L16.9691 10.001L10.1516 16.7182H6.898V13.5125Z" fill="#696969"/>
  </svg>
  );
  
  export const EditIcon = (props) => (
    <span className="ico">
      <Icon component={EditSvg} {...props} />
    </span>
  );

const OrganizerSvg = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.41667 8.33333C8.38913 8.33333 9.32176 7.94703 10.0094 7.25939C10.697 6.57176 11.0833 5.63913 11.0833 4.66667C11.0833 3.69421 10.697 2.76158 10.0094 2.07394C9.32176 1.38631 8.38913 1 7.41667 1C6.44421 1 5.51158 1.38631 4.82394 2.07394C4.13631 2.76158 3.75 3.69421 3.75 4.66667C3.75 5.63913 4.13631 6.57176 4.82394 7.25939C5.51158 7.94703 6.44421 8.33333 7.41667 8.33333Z" stroke="#77787C" stroke-width="1.7" stroke-linecap="round" strokeLinejoin="round"/>
  <path d="M1 15.6667V14.75C0.999777 13.6674 1.27347 12.6024 1.79561 11.654C2.31774 10.7056 3.07134 9.90475 3.98625 9.32598C4.90117 8.74721 5.94764 8.40933 7.02826 8.3438C8.10889 8.27827 9.18852 8.48722 10.1667 8.95119M11.6654 12.3804L12.6178 10.361C12.6436 10.3033 12.6855 10.2543 12.7386 10.2199C12.7916 10.1855 12.8535 10.1672 12.9167 10.1672C12.9799 10.1672 13.0417 10.1855 13.0948 10.2199C13.1478 10.2543 13.1897 10.3033 13.2155 10.361L14.1688 12.3804L16.2982 12.7068C16.5714 12.748 16.6796 13.1 16.4816 13.3017L14.9416 14.8719L15.3046 17.0912C15.3513 17.3763 15.0662 17.5935 14.8215 17.4588L12.9167 16.411L11.0118 17.4588C10.7671 17.5935 10.482 17.3763 10.5288 17.0921L10.8918 14.8719L9.35175 13.3017C9.15375 13.1 9.26192 12.748 9.53508 12.7059L11.6654 12.3804Z" stroke="#77787C" stroke-width="1.7" stroke-linecap="round" strokeLinejoin="round"/>
  </svg>
      );
      
export const OrganizerIcon = (props) => (
  <span className="ico" >
    <Icon component={OrganizerSvg} {...props} />
  </span>
);

const TitleSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.2073 3.20738C3.18819 3.50797 3.17119 3.84042 3.15738 4.19199C3.10003 5.63439 3.10746 7.27965 3.26572 8.52343L10.9822 16.2399L16.2398 10.9823L8.52335 3.2658C7.28064 3.10754 5.63431 3.10011 4.19191 3.15746C3.84034 3.17127 3.50895 3.18933 3.2073 3.20738ZM1.27845 1.27853C1.17542 1.37624 0.757994 6.12935 1.19666 9.06406C1.2434 9.37633 1.39741 9.65886 1.62046 9.88191L9.85528 18.1178C10.0032 18.2658 10.1789 18.3833 10.3723 18.4634C10.5656 18.5436 10.7729 18.5848 10.9822 18.5848C11.1915 18.5848 11.3988 18.5436 11.5922 18.4634C11.7855 18.3833 11.9612 18.2658 12.1092 18.1178L18.1177 12.1092C18.2658 11.9613 18.3832 11.7856 18.4633 11.5922C18.5435 11.3989 18.5847 11.1916 18.5847 10.9823C18.5847 10.773 18.5435 10.5657 18.4633 10.3724C18.3832 10.179 18.2658 10.0033 18.1177 9.85536L9.88183 1.62054C9.6619 1.39479 9.37514 1.24582 9.06398 1.19568C6.12927 0.759136 1.37616 1.17444 1.27845 1.27853Z" fill="#77787C"/>
  <path d="M7.7025 6.28994C7.80112 6.38862 7.87933 6.50577 7.93267 6.63468C7.98601 6.76359 8.01344 6.90174 8.0134 7.04125C8.01335 7.18076 7.98582 7.3189 7.93238 7.44777C7.87895 7.57665 7.80066 7.69373 7.70197 7.79235C7.60329 7.89096 7.48615 7.96917 7.35724 8.02252C7.22832 8.07586 7.09017 8.10329 6.95066 8.10324C6.81115 8.10319 6.67301 8.07566 6.54414 8.02223C6.41527 7.96879 6.29818 7.8905 6.19957 7.79181C6.0004 7.59251 5.88857 7.32226 5.88867 7.0405C5.88877 6.75875 6.00079 6.48857 6.2001 6.28941C6.3994 6.09025 6.66965 5.97842 6.95141 5.97852C7.23316 5.97862 7.50334 6.09064 7.7025 6.28994Z" fill="#77787C"/>
  </svg>
);
      
export const TitleIcon = (props) => (
  <span className="ico">
    <Icon component={TitleSvg} {...props} />
  </span>
);


