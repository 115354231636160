import moment from "moment";
import "moment-timezone";

export const ETimezone = {
  BANGKOK: "Asia/Bangkok",
};
moment.tz.setDefault(ETimezone.BANGKOK);

export const formatEventTimeToBangkok = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const convertToGMTPlus7 = (date) => {
  return moment(date.toString() + "+7");
};

export const formatBookingDateToBangkokString = (bookingDate) => {
  return moment(bookingDate).toString();
};

export const formatBookingDateTimeToBangkok = (bookingDate) => {
  return moment(bookingDate).format("HH:mm:ss");
};

export const convertToBangkokStartOfDay = (date) => {
  return moment(date).startOf("day");
};
