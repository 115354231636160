import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';
import Table from 'antd/lib/table';
import message from 'antd/lib/message';
import dayjs from 'dayjs';
import { toggleMemberLockedStatus, getLogs } from '../../api';
import './../../App.css';
import { CSVLink } from 'react-csv';

const identifyPlatform = (deviceID) => {
  const iosPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const androidPattern = /^[0-9a-fA-F]+$/;

  if (iosPattern.test(deviceID)) {
    return 'iOS';
  } else if (androidPattern.test(deviceID) && deviceID.length === 16) {
    return 'Android';
  } else {
    return 'Unknown';
  }
};

const formatDataForCSV = (logs) => {
  return logs.map((record) => ({
    DateTime: dayjs(record.createdAt).format('YYYY-MM-DD HH:mm'),
    FullName: record.member?.firstName
      ? record.member.title +
        ' ' +
        record.member?.firstName +
        ' ' +
        record.member.lastName
      : '-',
    Action: record.action,
    Description: record.description,
    IP: record.ip,
    Platform: identifyPlatform(record.deviceId),
    AppVersion: record.version,
  }));
};

const Lists = () => {
  const [logs, setLogs] = useState([]);
  const [logExport, setLogExport] = useState([]);

  const fetchLogs = async () => {
    const allLog = await getLogs();
    setLogs(allLog.data);
    const formattedData = formatDataForCSV(allLog.data);

    setLogExport(formattedData);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const columns = [
    {
      title: 'Date Time',
      dataIndex: 'dateTime',
      key: 'dateTime',

      render: (_, record) => (
        <div>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
      ),
    },
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => (
        <div>
          {record.member?.firstName
            ? record.member.title +
              ' ' +
              record.member?.firstName +
              ' ' +
              record.member.lastName
            : '-'}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => <div>{record.action}</div>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => <div>{record.description}</div>,
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      render: (_, record) => <div>{record.ip}</div>,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, record) => <div>{identifyPlatform(record.deviceId)}</div>,
    },
    {
      title: 'App version',
      dataIndex: 'appVersion',
      key: 'appVersion',
      render: (_, record) => <div>{record.version}</div>,
    },
  ];

  const handleLockedSwitchChange = async (checked, record) => {
    const update = await toggleMemberLockedStatus(record.id, checked);
    if (update) {
      fetchLogs();
      message.success('Member locked status updated!');
    }
  };

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Logs</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Log Lists</h5>
                <div className="create-button-container">
                  <CSVLink
                    filename={`activity-logs.csv`}
                    data={logExport}
                    className="btn btn-primary ml-5"
                  >
                    <DownloadOutlined /> Export to CSV
                  </CSVLink>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={
                    logs && logs.length > 0
                      ? logs.map((log) => ({
                          ...log,
                          key: log.id,
                        }))
                      : []
                  }
                  columns={columns}
                  scroll={{
                    x: 1000,
                  }}
                  pagination={{
                    showTotal: (total, range) =>
                      `${total} members | Showing ${range[0]}-${range[1]}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
