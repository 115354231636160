import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function googleAuthentication(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BASE_URL}/auth/google`, config);

  return response.data;
}
