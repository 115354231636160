import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';

import { getHouses, deleteHouse } from '../../api';
import './../../App.css';

const Lists = () => {
  const [houses, setHouses] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const fetchEvents = useCallback(async () => {
    const data = await getHouses(pagination.current, pagination.pageSize);
    setHouses(data);
  }, [pagination]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, pagination]);

  const handleDelete = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteHouse(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
            fetchEvents();
          } else {
            Swal.fire(
              'Error',
              'An error occurred while deleting the item.',
              'error'
            );
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while deleting the item.',
            'error'
          );
        }
      }
    });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) =>
        record.image ? (
          <Image src={record.image} width={80} height={45} />
        ) : (
          <Image
            src="/assets/images/events/default-cover.png"
            width={80}
            height={45}
          />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      render: (_, record) => (
        <div>
          <Link to={`/houses/${record.id}/members`}>
            {record.members.length}
          </Link>
        </div>
      ),
    },
    {
      title: 'Last Message',
      dataIndex: 'lastMessage',
      key: 'lastMessage',
      render: (_, record) => (
        <div>{record.lastMessage ? record.lastMessage.message : '-'}</div>
      ),
    },
    {
      title: 'Last Message DateTime',
      dataIndex: 'lastMessageDateTime',
      key: 'lastMessageDateTime',
      render: (_, record) => (
        <div>
          {record.lastMessage
            ? dayjs(record.lastMessage.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
        </div>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div>{record.owner.nickname}</div>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <div>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'locked',
      key: 'locked',
      render: (_, record) => (
        <div className="button-group">
          <Link
            to={`/houses/${record.id}/edit`}
            className="btn btn-warning btn-sm"
          >
            <EditOutlined /> Edit
          </Link>
          <button
            className="btn btn-danger btn-sm ml-5"
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined /> Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <Link to="/houses/create" className="btn btn-primary float-end mt-n1">
          <PlusOutlined /> New House
        </Link>

        <h1 className="h3 mb-3">Houses</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Houses</h5>
                <div className="create-button-container"></div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={houses}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: houses?.meta?.totalItems
                      ? houses.meta.totalItems
                      : 0,
                  }}
                  onChange={handleTableChange}
                  size="middle"
                  scroll={{
                    x: 'calc(700px + 50%)',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
