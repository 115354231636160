import React from 'react'
import { Line } from '@ant-design/plots';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
const LineChart = () => {
        const data = [
          { year: 'JAN', value: 3 },
          { year: 'FAB', value: 4 },
          { year: 'MAR', value: 3.5 },
          { year: 'APR', value: 5 },
          { year: 'MAY', value: 4.9 },
          { year: 'JUN', value: 6 },
          { year: 'JUL', value: 7 },
          { year: 'AUG', value: 9 },
          { year: 'SEP', value: 13 },
          { year: 'OCT', value: 13 },
          { year: 'NOV', value: 13 },
          { year: 'DEC', value: 13 },
        ];
        const config = {
          height:280,
          data,
          xField: 'year',
          yField: 'value',
          point: {
            shapeField: 'square',
            sizeField: 4,
          },
          interaction: {
            tooltip: {
              marker: false,
            },
          },
          style: {
            lineWidth: 2,
          },
        };

  return (
      <Line {...config} />
  )
}

export default LineChart
