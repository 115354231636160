import React, { useState } from 'react';
import Sidebar from './Partials/Sidebar';
import Navbar from './Partials/Navbar';
import Footer from './Partials/Footer';
import './../../../App.css';

const AdminLayout = ({ children }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <div className="wrapper">
      <Sidebar isCollapsed={isCollapsed} />

      <div className="main">
        <Navbar onToggle={() => setCollapsed(!isCollapsed)} />

        <main className="content p-4">{children}</main>

        <Footer />  
      </div>
    </div>
  );
};

export default AdminLayout;
