import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';

import { getHouses, deleteHouse } from '../../api';
import './../../App.css';

const Lists = () => {
  const [houses, setHouses] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const fetchEvents = useCallback(async () => {
    const data = await getHouses(pagination.current, pagination.pageSize);
    setHouses(data);
  }, [pagination]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, pagination]);

  const handleDelete = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteHouse(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
            fetchEvents();
          } else {
            Swal.fire(
              'Error',
              'An error occurred while deleting the item.',
              'error'
            );
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while deleting the item.',
            'error'
          );
        }
      }
    });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const columns = [
    {
      title: 'Cover',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) =>
        record.image ? (
          <Image src={record.image} width={120} height={68} />
        ) : (
          <Image
            src="/assets/images/events/default-cover.png"
            width={120}
            height={68}
          />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <div>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'locked',
      key: 'locked',
      render: (_, record) => (
        <div className="button-group">
          <Link to={`/events/${record.id}/edit`}>
            <Button
              className="custom-button"
              type="primary"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>
          <Button
            className="custom-button"
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Houses</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">House Lists</h5>
                <div className="create-button-container">
                  <Link to="/houses/create" className="create-button">
                    Create
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={houses}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: houses?.meta?.totalItems
                      ? houses.meta.totalItems
                      : 0,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
