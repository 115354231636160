import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  CrownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Image, Avatar, Tooltip } from "antd";
import { DateIcon, OrganizerIcon, TitleIcon } from "../../components/AppIcon";

import "moment-timezone";
import moment from "moment";
import { ETimezone } from "../../until/date";
moment.tz.setDefault(ETimezone.BANGKOK);
const guestBg = [
  "#0f90e8",
  "#ff920d",
  "#e882d0",
  "#0D65CB",
  "#0cb778",
  "#7a25d4",
];
const randomBg = (index) => {
  const generateRandomHexColor = (guestBg) => {
    return guestBg[(index + 1) % 5];
  };
  const randomColor = generateRandomHexColor(guestBg);
  return randomColor;
};

const GenerateGuest = memo(
  ({ firstName, lastName, phone, email, label, index }) => {
    return (
      <Tooltip
        title={
          <div className="d-flex flex-column">
            <span>
              {firstName} {lastName}
            </span>
            <span>{phone}</span>
            <span>{email}</span>
          </div>
        }
        placement="top"
        key={"guest_" + index}
      >
        <Link to="#">
          <Avatar
            className="cursor-pointer"
            style={{ backgroundColor: randomBg(index) }}
          >
            {label && label.split("")[0]}
          </Avatar>
        </Link>
      </Tooltip>
    );
  }
);

const ContentEvent = ({
  view,
  booking,
  setIsModalNote,
  equipments,
  eventDetail,
  isPreview = true,
  memberData,
}) => {
  const location = useLocation();

  function formatDateRange(value) {
    const date = new Date(value);
    const dateOptions = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      timeZone: ETimezone.BANGKOK,
    };
    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const formattedDate = dateFormatter.format(date);
    return formattedDate;
  }

  function formatTimeRange(input) {
    let date;

    if (input.includes("T") || input.includes("Z")) {
      date = moment(input);
    } else {
      date = moment(new Date());
      const [hours, minutes] = input.split(":");
      date.set("hours", parseInt(hours, 10));
      date.set("minutes", parseInt(minutes, 10));
      date.set("seconds", 0);
    }

    const hours = date.get("hours").toString().padStart(2, "0");
    const minutes = date.get("minutes").toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const calcAttendees = () => {
    const isHasOrgInList = eventDetail?.attendees?.[0]?.id === eventDetail?.organizer?.id
    let total = 0
    if(eventDetail?.organizer && !isHasOrgInList){
      total +=1
    }
    total += eventDetail?.attendees?.length
    if(total > eventDetail?.numberOfAttendees){
      total = eventDetail?.numberOfAttendees
    }
    return total
  }
  return (
    <div
      className={`bg-white overflow-hidden ${view == "create" ? "shadow" : ""}`}
    >
      <div className="room-img-wrap">
        {booking?.img ? (
          <Image
            width={"100%"}
            style={{
              maxHeight: "250px",
              overflow: "hidden",
              objectFit: "cover",
              borderRadius: "6px",
            }}
            src={
              booking?.resourceId !== "holiday"
                ? booking?.img
                : "/assets/images/holiday.png"
            }
          />
        ) : (
          <div className="blank-img"></div>
        )}
      </div>
      <div className="item-detail-list-wrap">
        <div className="pb-3 text-base">
          <div className="fs-2 fw-bold mb-2">
            {booking?.name || "Room Name"}
          </div>
          <div className="d-flex align-items-center fw-bolder pb-2">
            <div className="pe-2">
              <TitleIcon />
            </div>
            <div className="content-event-title">
              {eventDetail?.title || "Title"}
            </div>
          </div>
          <ul className="list-unstyled d-flex flex-column gap-2 mb-0">
            <li className="d-flex align-items-center">
              <div className="pe-2">
                <DateIcon />
              </div>{" "}
              <span className="text-sm">
                {eventDetail?.date ? formatDateRange(eventDetail?.date) : ""}{" "}
                {!eventDetail?.startTime && !eventDetail?.endTime
                  ? "Time"
                  : `${formatTimeRange(
                      eventDetail?.startTime
                    )} - ${formatTimeRange(eventDetail?.endTime)}`}
              </span>
            </li>
            <li className="d-flex align-items-center">
              <div className="pe-2">
                <OrganizerIcon />
              </div>
              {eventDetail?.organizerId ? (
                <span className="text-sm">
                  {eventDetail?.organizerId?.firstName}{" "}
                  {eventDetail?.organizerId?.lastName}
                </span>
              ) : eventDetail?.organizer ? (
                <span className="text-sm">
                  {" "}
                  {eventDetail?.organizer?.firstName}{" "}
                  {eventDetail?.organizer?.lastName}
                </span>
              ) : (
                <span>Name Owner</span>
              )}
            </li>
            <li className="d-flex align-items-center">
              <div className="pe-2">
                <i
                  className={`d-block bg-${
                    eventDetail?.status || `status-default`
                  }`}
                  style={{
                    marginLeft: "3px",
                    width: "14px",
                    height: "14px",
                  }}
                ></i>
              </div>
              <span className="text-sm">{eventDetail?.status || `Status`}</span>
            </li>
          </ul>
        </div>
        <div className="pb-3 text-base">
          <div className="fw-bolder pb-2">Equipment</div>
          <div className="listEquipment">
            <ul className="d-flex flex-wrap list-unstyled mb-0">
              {eventDetail?.equipments?.length
                ? eventDetail?.equipments?.map?.(
                    (e, i) =>
                      e.isSelected && (
                        <li
                          className={`px-2 d-flex align-items-center ${
                            i == 0 ? "" : "hasDot"
                          }`}
                          key={"eqm_" + i}
                        >
                          <span className={`${i == 0 ? "" : "ps-3"}`}>
                            {e?.label || e}
                          </span>
                        </li>
                      )
                  )
                : booking?.equipments
                ? booking?.equipments?.map?.((e, i) => (
                    <li
                      className={`px-2 d-flex align-items-center ${
                        i == 0 ? "" : "hasDot"
                      }`}
                      key={"eqm_" + i}
                    >
                      <span className={`${i == 0 ? "" : "ps-3"}`}>
                        {e.label}
                      </span>
                    </li>
                  ))
                : "-"}
            </ul>
          </div>
        </div>
        <div className="pb-3 text-base">
          <div className="fw-bolder pb-2">Special Request</div>
          <div className="content-event-special-request">
            {eventDetail?.specialRequest || "-"}
          </div>
        </div>
        <div className="pb-3 text-base">
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-bolder pb-2">
              Attendees List{" "}
              <span className="fw-bolder text-sm">
              {calcAttendees()} /{" "}
                {eventDetail?.numberOfAttendees}
              </span>
            </div>
            <div>
              <Button
                shape="round"
                onClick={() => setIsModalNote(true)}
                className="btn btn-dark btn-note"
              >
                Note
              </Button>
            </div>
          </div>
          {isPreview && (
            <>
              <div>
                <div
                  style={{
                    paddingBottom: memberData?.length > 0 ? "8px" : "37px",
                  }}
                >
                  Member
                </div>
                {memberData?.length > 0 || eventDetail?.organizer ? (
                  <ul className="list-unstyled row">
                    {eventDetail?.organizer && (
                      <li className="col-6 d-flex align-items-center mb-3">
                        <Tooltip
                          title={
                            <div className="d-flex flex-column">
                              <span>{eventDetail?.organizer?.label}</span>
                              <span>{eventDetail?.organizer?.phone}</span>
                              <span>{eventDetail?.organizer?.email}</span>
                            </div>
                          }
                          placement="top"
                        >
                          <Link to="#" className="attendee-card">
                            <Avatar
                              size={54}
                              src={eventDetail?.organizer?.avatar}
                              icon={<UserOutlined />}
                            />
                            <span className="text-xs fw-bold ps-2 text-dark">
                              {eventDetail?.organizer?.firstName}{" "}
                              {eventDetail?.organizer?.lastName}
                            </span>
                          </Link>
                        </Tooltip>
                      </li>
                    )}
                    {memberData?.map((e, i) => {
                      if (e.type === "MEMBER") {
                        return (
                          <li
                            className="col-6 d-flex align-items-center mb-3"
                            key={`member_${i}_${new Date().getTime()}`}
                          >
                            <Tooltip
                              title={
                                <div className="d-flex flex-column">
                                  <span>{e.label}</span>
                                  <span>{e.phone}</span>
                                  <span>{e.email}</span>
                                </div>
                              }
                              placement="top"
                            >
                              <Link to="#" className="attendee-card">
                                <Avatar
                                  size={54}
                                  src={e.avatar}
                                  icon={<UserOutlined />}
                                />
                                <span className="text-xs fw-bold ps-2 text-dark">
                                  {e.firstName} {e.lastName}
                                </span>
                              </Link>
                            </Tooltip>
                          </li>
                        );
                      }
                    })}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className="pb-2">Guest</div>
                {memberData?.length > 0 ? (
                  <div>
                    <Avatar.Group
                      maxCount={4}
                      size={54}
                      maxStyle={{
                        color: "#FFFFFF",
                        backgroundColor: "#000000",
                      }}
                    >
                      {eventDetail?.attendees.map((e, i) => {
                        if (e.type === "GUEST") {
                          return (
                            <GenerateGuest
                              firstName={e?.firstName}
                              lastName={e?.lastName}
                              phone={e?.phone}
                              email={e?.email}
                              label={e?.label}
                              index={i}
                              key={e.value + `${new Date().getTime()}`}
                            />
                          );
                        }
                      })}
                    </Avatar.Group>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="pt-3 text-xs text-muted">
                <span className="text-danger">*</span> Display depends on the
                device being used.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentEvent;
