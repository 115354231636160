import AxiosInstance from "./axios";


export async function getLogs() {
  const response = await AxiosInstance.get(`/member-logs`);
  return response;
}

export async function getMemberLogs(id) {
  const response = await AxiosInstance.get(
    `/member-logs/${id}/lists`
  );
  return response;
}
