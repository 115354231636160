import React from 'react';
import { Form, Carousel, Row, Col, Avatar, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const { Title, Text } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone); // adjust based on your actual libraries

const ArticleLivePreview = ({ formik }) => {
  return (
    <div className="col-12 col-lg-4 live-preview">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Live preview</h5>
        </div>
        <div className="card-body">
          {formik.values.images ? (
            <Carousel>
              {formik.values.images.map((file, index) => (
                <div key={index}>
                  {file && file.thumbUrl ? (
                    <div className="image-container">
                      <img
                        src={file.thumbUrl}
                        alt={`slide ${index}`}
                        className={`carousel-image ${
                          file.aspectRatio === 1
                            ? 'square-image'
                            : 'canvas-image'
                        }`}
                      />
                    </div>
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
              ))}
            </Carousel>
          ) : null}
          <Form
            {...{
              labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
              },
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
              },
            }}
            onSubmit={formik.handleSubmit}
          >
            <div className="title">{formik.values.title}</div>
            <div className="post-date">
              {dayjs().format('ddd, MMM D h:mm A')}
            </div>
            <div className="height-32" />
            <div
              dangerouslySetInnerHTML={{
                __html: formik.values.description.replace(/\n/g, '<br />'),
              }}
            />
            <div className="height-24" />
            <div className="created-by">Create by</div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar size={64} src={'/assets/images/icon.png'} />
              <div style={{ marginLeft: '16px' }}>
                <Title level={5} style={{ margin: 0 }}>
                  H.O.W. bangkok
                </Title>
                <Text type="secondary" style={{ margin: 0 }}>
                  Official
                </Text>
              </div>
            </div>
            <div className="height-32" />
            <Row>
              <Col span={12} className="last-updated">
                <div>Last update</div>
              </Col>
              <Col
                span={12}
                style={{ textAlign: 'right' }}
                className="updated-time"
              >
                <div>Sat, Mar 16 10:00 AM</div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ArticleLivePreview;
