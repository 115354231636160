import React from "react";
import { Calendar } from "antd";
import "./dashboard.css";
const CalendarDash = () => {
  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  const wrapperStyle = {
    height: "100%",
  };
  return (
    <div style={wrapperStyle}>
      <Calendar
        onPanelChange={onPanelChange}
        mode={"month"}
        className="dashCalendar"
      />
    </div>
  );
};

export default CalendarDash;
