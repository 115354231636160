import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ArticleListPage,
  ArticleCreatePage,
  ArticleEditPage,
  EventCreatePage,
  EventEditPage,
  EventAttendeesListPage,
  EventAddMemberPage,
  FaqCreatePage,
  FaqEditPage,
  FaqListPage,
  HouseCreatePage,
  HouseEditPage,
  HouseListPage,
  HouseMemberListPage,
  PrivilegeCreatePage,
  PrivilegeEditPage,
  PrivilegeListPage,
  DashboardPage,
  RoomPage,
  CreateBooking,
  LoginPage,
  EventListPage,
  MemberEditPage,
  MemberListPage,
  MemberLogListPage,
  CheckInPage,
  CheckOutPage,
  LogListPage,
} from './pages';

import PrivateRoute from './PrivateRoute';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<MemberListPage />} />
          <Route path="/members/:id/edit" element={<MemberEditPage />} />
          <Route path="/members/:id/logs" element={<MemberLogListPage />} />

          {/* <Route path="/" element={<DashboardPage />} /> */}

          <Route path="/articles/:id/edit" element={<ArticleEditPage />} />
          <Route path="/articles/create" element={<ArticleCreatePage />} />
          <Route path="/articles" element={<ArticleListPage />} />

          <Route
            path="/events/:id/add-member"
            element={<EventAddMemberPage />}
          />
          <Route
            path="/events/:id/attendees"
            element={<EventAttendeesListPage />}
          />
          <Route path="/events/:id/edit" element={<EventEditPage />} />
          <Route path="/events/create" element={<EventCreatePage />} />
          <Route path="/events" element={<EventListPage />} />

          <Route path="/houses/:id/members" element={<HouseMemberListPage />} />
          <Route path="/houses/:id/edit" element={<HouseEditPage />} />
          <Route path="/houses/create" element={<HouseCreatePage />} />
          <Route path="/houses" element={<HouseListPage />} />

          <Route path="/faqs/:id/edit" element={<FaqEditPage />} />
          <Route path="/faqs/create" element={<FaqCreatePage />} />
          <Route path="/faqs" element={<FaqListPage />} />

          <Route path="/privileges/:id/edit" element={<PrivilegeEditPage />} />
          <Route path="/privileges/create" element={<PrivilegeCreatePage />} />
          <Route path="/privileges" element={<PrivilegeListPage />} />

          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/meeting-rooms" element={<RoomPage />} />
          <Route path="/meeting-rooms/create-booking" element={<CreateBooking />} />
          <Route path="/meeting-rooms/edit-booking/:id" element={<CreateBooking />} />

          <Route path="/check-in" element={<CheckInPage />} />
          <Route path="/check-out" element={<CheckOutPage />} />

          <Route path="/logs" element={<LogListPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
