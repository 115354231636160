import React, { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import { CrownOutlined } from "@ant-design/icons";
import moment from "moment";
const EventBar = (
  event,
  legends,
  setEventSelect,
  setIsModalBooking,
  setIsModalBookingDetail,
  editBooking
) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    if (event.label) {
      const times = event.label.split(" – ");
      setStartTime(moment(times[0], "h:mm A").format("HH:mm"));
      setEndTime(moment(times[1], "h:mm A").format("HH:mm"));
    }
  }, []);

  let findLegend = legends.find((e) => {
    return e.key == event.event.room;
  });
  const eventClick = (elem) => {
    setEventSelect(event.event);
    if (event.type === "date") {
      setIsModalBooking(true);
    } else {
      editBooking(event.event.id);
    }
  };

  return (
    <>
      {event.type === "date" ? (
        <div
          className={`eventBar ${event.event.room}`}
          style={{ backgroundColor: findLegend?.color }}
          onClick={eventClick}
        >
          {event.event.room === "holiday" ? (
            <div>Special holidays</div>
          ) : (
            <div>
              {event.event.title} <span>[{event.event.meet} meet]</span>
            </div>
          )}
        </div>
      ) : (
        <Popover
          content={
            <div>
              {event?.event?.creator?.firstName +
                " " +
                event?.event?.creator?.lastName}
              <br />
              {event.label}
            </div>
          }
          title={event.event.title}
        >
          <div
            role="button"
            tabIndex="0"
            className={`eventBar timeBar rbc-event overflow-y-auto overflow-x-hidden py-1 pb-2 border-1 border-white    ${event.event.room}`}
            style={{
              backgroundColor: findLegend?.color || "",
              top: event.style.top + "%",
              left: event.style.left + "%",
              height: event.style.height,
              width: event.style.width,
              xOffset: event.style.xOffset,
              scrollbarWidth: "none",
            }}
            onClick={eventClick}
          >
            <div className=" d-flex flex-column gap-1">
              <div className=" fw-bold text-truncate w-100 text-base">
                {event.event.title}
              </div>
              <div className=" d-flex align-items-end">
                <CrownOutlined className=" text-base me-1" />{" "}
                {event?.event?.creator?.firstName +
                  " " +
                  event?.event?.creator?.lastName}
              </div>
              <div className="text-truncate">{`[${startTime} - ${endTime}]`}</div>
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default EventBar;
