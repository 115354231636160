import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';
import Badge from 'antd/lib/badge';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';

import { getPrivileges, deletePrivilege } from '../../api';
import './../../App.css';

const renderStatusBadge = (privilege) => {
  const { quantityAvailable, quantityUsage } = privilege;
  if (new Date(privilege.validityStart) > new Date()) {
    return <Badge color="#B0B0B0" text="Inactive" />;
  } else if (new Date(privilege.validityEnd) < new Date()) {
    return <Badge color="#DC3545" text="Expired" />;
  } else if (quantityAvailable <= quantityUsage) {
    return <Badge color="orange" text="Depleted" />;
  } else {
    return <Badge color="#28A745" text="Active" />;
  }
  // <Badge color="#B0B0B0" text="Inactive" />
  // <Badge color="#28A745" text="Active" />
  // <Badge color="#DC3545" text="Expired" />
  // <Badge color="#FF5733" text="Depleted" />
  // switch(status) {
  //   case 'Inactive':
  //     return <Badge color="#B0B0B0" text="Inactive" />;
  //   case 'Active':
  //     return <Badge color="#28A745" text="Active" />;
  //   case 'Expired':
  //     return <Badge color="#DC3545" text="Expired" />;
  //   case 'Depleted':
  //     return <Badge color="#FF5733" text="Depleted" />;
  //   default:
  //     return null;
  // }
};

const Lists = () => {
  const [privileges, setPrivileges] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const fetchPrivileges = useCallback(async () => {
    const data = await getPrivileges(pagination.current, pagination.pageSize);
    setPrivileges(data);
  }, [pagination]);

  useEffect(() => {
    fetchPrivileges();
  }, [fetchPrivileges, pagination]);

  const handleDelete = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deletePrivilege(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
            fetchPrivileges();
          } else {
            Swal.fire(
              'Error',
              'An error occurred while deleting the item.',
              'error'
            );
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while deleting the item.',
            'error'
          );
        }
      }
    });
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const columns = [
    {
      title: 'Cover',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) =>
        record.image ? (
          <Image src={record.image} width={120} height={68} />
        ) : (
          <Image
            src="/assets/images/privileges/default-cover.png"
            width={120}
            height={68}
          />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (_, record) => (
        <div>
          {record.quantityAvailable - record.quantityUsage} /
          {record.quantityAvailable}
        </div>
      ),
    },
    {
      title: 'Effective Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <div>
          {`${dayjs(record.validityStart).format('D MMM YYYY')} - ${dayjs(
            record.validityEnd
          ).format('D MMM YYYY')}`}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <>{renderStatusBadge(record)}</>,
    },
    {
      title: 'Action',
      dataIndex: 'locked',
      key: 'locked',
      render: (_, record) => (
        <div className="button-group">
          <Link to={`/privileges/${record.id}/edit`}>
            <Button
              className="custom-button"
              type="primary"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>
          <Button
            className="custom-button"
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Privileges</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Privilege Lists</h5>
                <div className="create-button-container">
                  <Link to="/privileges/create" className="create-button">
                    Create
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={privileges?.items}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: privileges?.meta?.totalItems
                      ? privileges.meta.totalItems
                      : 0,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
