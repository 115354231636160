import React from "react";
import { Pie } from "@ant-design/plots";
const DonutChart = ({ data, color, type, total }) => {
  const config = {
    data: data,
    percent: true,
    angleField: "value",
    colorField: "name",
    innerRadius: 0.7,
    padding: 60,
    style: {
      stroke: "#fff",
      inset: 1,
      radius: 0,
    },
    label: {
      position: "outside",
      text: "value",
      formatter: (text) => `${text} %`,
      fontWeight: 700,
      fontSize: "10px",
      connector: 0,
      connectorLineWidth: 0,
      fill: (_, index) => color[index],
    },
    scale: {
      color: {
        range: color,
      },
    },
    legend: {
      color: {
        title: false,
        position: "bottom",
        rowPadding: 5,
        maxCols: 2,
        cols: 2,
        itemLabelFontWeight: 700,
        itemLabelFontSize: 13
        },
    },
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div
        className="total-amount-graph"
      >
        <p style={{ fontSize: "13px" }}>
          {type === "attendance" ? "Total Attendance" : "Total Booking"}
        </p>
        <p className={`${type}-total`}>{total || "0"}</p>
      </div>
      <Pie {...config} />
    </div>
  );
};
export default DonutChart;
