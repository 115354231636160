import AxiosInstance from "./axios";

export async function getPrivileges(currentPage, pageSize) {
  const response = await AxiosInstance.get(
    `/privileges?page=${currentPage}&limit=${pageSize}`
  );
  return response.data;
}

export async function getPrivilege(id) {
  const response = await AxiosInstance.get(`/privileges/${id}`);
  console.log(response.data.statusCode);
  return response;
}

export async function createPrivilege(formData) {
  const response = await AxiosInstance.post(`/privileges`, formData);

  return response;
}

export async function updatePrivilege(id, formData) {
  const response = await AxiosInstance.patch(`/privileges/${id}`, formData);
  return response;
}

export async function deletePrivilege(id) {
  const response = await AxiosInstance.delete(`/privileges/${id}`);
  return response;
}
