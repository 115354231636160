import React, { useRef, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
  BookTwoTone,
} from "@ant-design/icons";
import { Tooltip, Image } from "antd";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import Table from "antd/lib/table";
import Switch from "antd/lib/switch";
import message from "antd/lib/message";
import Button from "antd/lib/button";
import { Link } from "react-router-dom";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import {
  getMembers,
  getMemberTiers,
  deleteMember,
  toggleMemberLockedStatus,
} from "../../api";
import "./../../App.css";

const Lists = () => {
  const [members, setMembers] = useState([]);
  const [memberTiers, setMemberTiers] = useState([]);
  const [memberExport, setMemberExport] = useState([]);

  const fetchMembers = async () => {
    const tiers = await fetchMemberTiers();
    const allMember = await getMembers();
    setMembers(allMember.data);

    const formattedData = formatDataForCSV(allMember.data, tiers);
    setMemberExport(formattedData);
  };

  const fetchMemberTiers = async () => {
    const data = await getMemberTiers();
    console.log(data);
    setMemberTiers(data);
    return data;
  };

  const handleDelete = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(record);
        try {
          const response = await deleteMember(record.id);

          if (response.status === 200) {
            Swal.fire("Deleted!", "The item has been deleted.", "success");
            fetchMembers();
          } else {
            Swal.fire(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const formatDataForCSV = (logs, tiers) => {
    console.log(tiers);
    return logs.map((record) => ({
      FullName: record.firstName
        ? record.title + " " + record.firstName + " " + record.lastName
        : "-",
      Nickname: record?.nickname || '',
      Note: record?.note || '',
      Phone: record.phone ? `'${record.phone}` : "-",
      Email: record.email ? record.email : "-",
      Tier: tiers.find((item) => item.id === record.tierId)?.name || "-",
      RegisterDate: record.createdAt
        ? `${dayjs(record.createdAt).format("YYYY-MM-DD HH:mm")}`
        : "-",
      ExpiredDate: record.periodEnd
        ? `${dayjs(record.periodEnd).format("YYYY-MM-DD HH:mm")}`
        : "-",
      ProfileImage: record.avatar ? record.avatar : "-",
    }));
  };

  const columns = [
    {
      title: "Profile Image",
      dataIndex: "qrImage",
      key: "qrImage",
      render: (_, record) => (
        <>
          {record?.avatar ? (
            <Image id={`avatar-${record.key}`}src={record.avatar} width={71} height={71} preview={false} />
          ) : (
            <div>
              <div id={`qr-${record.key}`}>
                <QRCode
                  size={50}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={record.id}
                  viewBox={`0 0 256 256`}
                />
              </div>

              <a onClick={() => downloadQRCode(record)}>Download</a>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        const nameA = `${a.title} ${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.title} ${b.firstName} ${b.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Full Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const fullName =
          `${record.title} ${record.firstName} ${record.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (_, record) => (
        <div>
          {record.title} {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (a, b) => {
        const nameA = `${a.nickname}`.toLowerCase();
        const nameB = `${b.nickname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Nickname"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const fullName = `${record.nickname}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (_, record) => <div>{record.nickname}</div>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => (a.phone || "").localeCompare(b.phone || ""),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Phone`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.phone
          ? record.phone.toString().toLowerCase().includes(value.toLowerCase())
          : "",
      render: (_, record) => <div>{record.phone ? record.phone : "-"}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.email
          ? record.email.toString().toLowerCase().includes(value.toLowerCase())
          : "",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      sorter: (a, b) => {
        const noteA = `${a.note}`.toLowerCase();
        const noteB = `${b.note}`.toLowerCase();
        return noteA.localeCompare(noteB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Note"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const note = `${record.note}}`.toLowerCase();
        return note.includes(value.toLowerCase());
      },
      render: (_, record) => (
        <>
          {record.note ? (
            <Tooltip title={record.note} overlayStyle={{ whiteSpace: 'pre-line' }}>
              <b className="btn btn-link text-decoration-underline text-black">
                Note
              </b>
            </Tooltip>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Tier",
      dataIndex: "tierId",
      key: "tierId",
      sorter: (a, b) => {
        let tierA = memberTiers.find((item) => item.id === a.tierId);
        let tierB = memberTiers.find((item) => item.id === b.tierId);

        let nameA = tierA ? tierA.name : "";
        let nameB = tierB ? tierB.name : "";

        return nameA.localeCompare(nameB);
      },
      render: (_, record) => {
        let tier = memberTiers.find((item) => item.id === record.tierId);

        if (tier) {
          return tier.name;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Register At",
      dataIndex: "registeredDate",
      key: "registeredDate",
      sorter: (a, b) =>
        dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1,
      width: 130,
      render: (_, record) => (
        <>{dayjs(record.createdAt).format("DD MMM YYYY")}</>
      ),
    },
    {
      title: "Expire At",
      dataIndex: "expireDate",
      key: "expireDate",
      sorter: (a, b) => {
        const dateA = a.periodEnd ? dayjs(a.periodEnd).unix() : -Infinity;
        const dateB = b.periodEnd ? dayjs(b.periodEnd).unix() : -Infinity;
        return dateA - dateB;
      },
      width: 120,
      render: (_, record) => (
        <>
          {record.periodEnd
            ? dayjs(record.periodEnd).format("DD MMM YYYY")
            : "-"}
        </>
      ),
    },
    {
      title: "Locked",
      dataIndex: "locked",
      key: "locked",
      width: 80,
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.isLocked}
          onChange={(checked) => handleLockedSwitchChange(checked, record)}
          disabled={!record.isVerify}
        />
      ),
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
      width: 80,
      render: (_, record) => (
        <Link to={`/members/${record.id}/edit`}>
          <Button
            className="custom-button"
            type="primary"
            size="small"
            disabled={!record.isVerify}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        </Link>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 80,
      sorter: (a, b) => a.isVerify - b.isVerify,
      render: (_, record) => (
        <Button
          type="primary"
          icon={<DeleteOutlined style={{ verticalAlign: "middle" }} />}
          danger
          size="small"
          disabled={record.tierId}
          onClick={() => handleDelete(record)}
        >
          Delete
        </Button>
      ),
    },
    {
      title: "Log",
      dataIndex: "log",
      key: "log",
      width: 80,

      render: (_, record) => (
        <Link to={`/members/${record.id}/logs`}>
          <Button
            className="custom-button"
            type="primary"
            size="small"
            icon={<BookTwoTone />}
          >
            Logs
          </Button>
        </Link>
      ),
    },
  ];

  const handleLockedSwitchChange = async (checked, record) => {
    const update = await toggleMemberLockedStatus(record.id, checked);
    if (update) {
      fetchMembers();
      message.success("Member locked status updated!");
    }
  };

  const downloadQRCode = (record) => {
    const qrElement = document.getElementById(`qr-${record.key}`);
    html2canvas(qrElement).then((canvas) => {
      const pngUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = `qr-code-${record.nickname}-${record.firstName}-${record.lastName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <CSVLink
          filename={`members.csv`}
          data={memberExport}
          className="btn btn-secondary float-end mt-n1"
        >
          <DownloadOutlined /> Export CSV
        </CSVLink>
        <h1 className="h3 mb-3">Members</h1>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Members</h5>
              </div>
              <div className="card-body">
                <Table
                  dataSource={
                    members && members.length > 0
                      ? members.map((member) => ({
                          ...member,
                          key: member.id,
                        }))
                      : []
                  }
                  columns={columns}
                  scroll={{
                    x: 1000,
                  }}
                  pagination={{
                    showTotal: (total, range) =>
                      `${total} members | Showing ${range[0]}-${range[1]}`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Lists;
