import AxiosInstance from "./axios";

export async function getMembers() {
  const response = await AxiosInstance.get(`/members`);
  return response;
}

export async function getMember(id) {
  const response = await AxiosInstance.get(`/members/${id}`);
  return response;
}

export async function toggleMemberActiveStatus(id, activeStatus) {
  const response = await AxiosInstance.patch(`/members/${id}/active`, {
    active: activeStatus,
  });
  return response.data;
}

export async function updateMemberTier(id, tier) {
  const response = await AxiosInstance.patch(`/members/${id}/tier`, {
    tier: tier,
  });
  return response.data;
}

export async function toggleMemberLockedStatus(id, lockedStatus) {
  const response = await AxiosInstance.patch(`/members/${id}/locked`, {
    locked: lockedStatus,
  });
  return response.data;
}

export async function checkInToHow(memberId) {
  return await AxiosInstance.post(`/members/check-in/${memberId}`, {});
}

export async function checkOutFromHow(memberId) {
  return await AxiosInstance.post(`/members/check-out/${memberId}`, {});
}

export async function deleteMember(id) {
  const response = await AxiosInstance.delete(`/members/${id}`);
  return response;
}

export async function updateMember(id, formData) {
  const response = await AxiosInstance.patch(`/members/${id}`, formData);
  return response;
}

export async function getActiveMember() {
  const response = await AxiosInstance.get(`/members/active/`);
  return response.data;
}
