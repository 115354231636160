import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteOutlined, DownloadOutlined , ReloadOutlined} from "@ant-design/icons";
import Button from "antd/lib/button";
import Image from "antd/lib/image";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import { Checkbox, Row, Col, Modal, Divider } from "antd";
import DonutChart from "../../components/dashboard/DonutChart";
import "./event.css";

import {
  SearchOutlined,
  QrcodeOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AdminLayout from "../../components/layouts/AdminLayout/AdminLayout";
import { CSVLink } from "react-csv";

import {
  getEventWithAttendees,
  deleteEventAttendance,
  confirmEventAttendance,
  checkInByQRScan,
  addMemberWalkToEvent,
  getEventReport,
} from "../../api";
import "./../../App.css";
import "moment-timezone";
import moment from "moment";
import { ETimezone } from "../../until/date";
moment.tz.setDefault(ETimezone.BANGKOK);

const Attendees = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState();
  const [attendances, setAttendances] = useState([]);
  const [cameraActive, setCameraActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [reportAttended, setReportAttended] = useState();
  const [reportBooking, setReportBooking] = useState();
  const [totalBooking, setTotalBooking] = useState();
  const [showReport, setShowReport] = useState(false);
  const delay = 5000;

  const colorAttended = ["#7F56D9", "#0072F9"];

  const colorBooking = ["#8B67DD", "#FF4444", "#F7985A", "#A6A6A6"];

  const formatDataForCSV = (attendances) => {
    return attendances.map((attendance) => ({
      Name: `${attendance.member.title} ${attendance.member.firstName} ${attendance.member.lastName}`,
      Email: attendance.member.email,
      Phone: `'${attendance.member.phone}`,
      Company: attendance.member.company,
      "Join Date": dayjs(attendance.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      note: attendance.note,
      status: attendance.status,
    }));
  };
  const fetchEvent = useCallback(
    async (id) => {
      try {
        const eventResult = await getEventWithAttendees(id);
        await onGetEventReport()
        setEvent(eventResult.data);
        const formattedData = formatDataForCSV(eventResult.data.attendances);

        setAttendances(formattedData);
      } catch (error) {
        console.error(error);
        // navigate('/events');
      }
    },
    [navigate]
  );

  const handleAddMemberToEvent = () => {
    navigate(`/events/${id}/add-member`);
  };

  const calculatePercentage = (part, whole) => {
    return whole === 0 ? 0 : ((part / whole) * 100).toFixed(2);
  };

  const onGetEventReport = async () => {
    const response = await getEventReport(id);
    const totalAttended =
      response.data.attendedInformation.totalUnregister +
      response.data.attendedInformation.totalCheckedIn;
    const dataAttended = [
      {
        name: "Total checked-in",
        value: calculatePercentage(
          response.data.attendedInformation.totalCheckedIn,
          totalAttended
        ),
        total: response.data.attendedInformation.totalCheckedIn.toString(),
      },
      {
        name: "Total unregister",
        value: calculatePercentage(
          response.data.attendedInformation.totalUnregister,
          totalAttended
        ),
        total: response.data.attendedInformation.totalUnregister.toString(),
      },
    ];

    setReportAttended(dataAttended);

    const totalBooking =
      response.data.bookingInformation.totalCheckedIn +
      response.data.bookingInformation.totalAbsentee +
      response.data.bookingInformation.totalWaitList +
      response.data.bookingInformation.totalCanceled;

    setTotalBooking(totalBooking);

    const dataBooking = [
      {
        name: "Checked-in",
        value: calculatePercentage(
          response.data.bookingInformation.totalCheckedIn,
          totalBooking
        ),
        total: response.data.bookingInformation.totalCheckedIn,
      },
      {
        name: "Absentee",
        value: calculatePercentage(
          response.data.bookingInformation.totalAbsentee,
          totalBooking
        ),
        total: response.data.bookingInformation.totalAbsentee,
      },
      {
        name: "Waitlist",
        value: calculatePercentage(
          response.data.bookingInformation.totalWaitList,
          totalBooking
        ),
        total: response.data.bookingInformation.totalWaitList,
      },
      {
        name: "Booking cancel",
        value: calculatePercentage(
          response.data.bookingInformation.totalCanceled,
          totalBooking
        ),
        total: response.data.bookingInformation.totalCanceled,
      },
    ];
    setReportBooking(dataBooking);
  };

  const handleScan = async (memberId) => {
    if (isProcessing) {
      return;
    }
    if (memberId && setCameraActive) {
      setIsProcessing(true);
      try {
        const response = await checkInByQRScan(id, memberId);

        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Checked in successfully!",
          });
          fetchEvent(id);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data.message === "Attendance not found") {
            Swal.fire({
              icon: "warning",
              title: "Information not found !",
              text: `Can you confirm that “${error.response.data.error}” is check in at the event?`,
              showCancelButton: true,
              customClass: {
                confirmButton:
                  "btnSweetBlack px-5 event-confirm-attendees-check-in",
                cancelButton: "btnSweetDanger px-5",
                title: "text-black",
              },
              buttonsStyling: false,
              confirmButtonText: "Confirm",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  const response = await addMemberWalkToEvent(id, memberId);

                  if (response.status === 201) {
                    Swal.fire(
                      "Confirmed!",
                      "This Member has been confirmed.",
                      "success"
                    );
                    fetchEvent(id);
                  } else {
                    Swal.fire(
                      "Error",
                      "An error occurred while adding the user.",
                      "error"
                    );
                  }
                } catch (error) {
                  Swal.fire(
                    "Error",
                    "An error occurred while adding the user.",
                    "error"
                  );
                }
              }
            });
          } else {
            console.error("Data:", error.response.data);
            console.error("Status:", error.response.status);
            console.error("Headers:", error.response.headers);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
          });
          console.error("Error Message:", error.message);
        }
      }
      setIsProcessing(false);
      setCameraActive(false);
    }
  };

  const handleConfirm = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Can you confirm that "${record.member.title} ${record.member.firstName} ${record.member.lastName}" is check in at the event?`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btnSweetBlack px-5 event-confirm-attendees-check-in",
        cancelButton: "btnSweetDanger px-5",
        title: "text-black",
      },
      buttonsStyling: false,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await confirmEventAttendance(record.id);
          //     // Check the response code here
          if (response.status === 200) {
            Swal.fire(
              "Confirmed!",
              "This Member has been confirmed.",
              "success"
            );
            fetchEvent(id);
          } else {
            Swal.fire(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };
  const handleDelete = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btnSweetDanger px-5",
        cancelButton: "btnSweetBlack px-5",
        title: "text-black",
      },
      buttonsStyling: false,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteEventAttendance(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire("Deleted!", "The item has been deleted.", "success");
            fetchEvent(id);
          } else {
            Swal.fire(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  const fetchData = async () =>{
    await fetchEvent(id);
    await onGetEventReport();
  }

  useEffect(() => {
    fetchData()
  }, [fetchEvent, id]);

  useEffect(() => {
    if (reportAttended && reportBooking) {
      setShowReport(true);
    }
  }, [reportAttended, reportBooking]);

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (_, record) =>
        record.member?.avatar ? (
          <Image src={record.member.avatar} width={71} height={71} />
        ) : (
          <Image src="/assets/images/events/default-cover.png" width={80} />
        ),
    },
    {
      title: "Full Name",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => {
        const nameA =
          `${a.member.title} ${a.member.firstName} ${a.member.lastName}`.toLowerCase();
        const nameB =
          `${b.member.title} ${b.member.firstName} ${b.member.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Full Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const fullName =
          `${record.member.title} ${record.member.firstName} ${record.member.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (_, record) => (
        <div>
          {record.member?.title} {record.member?.firstName}{" "}
          {record.member?.lastName}
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) =>
        (a.member.phone || "").localeCompare(b.member.phone || ""),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Phone`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.member.phone
          ? record.member.phone
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      render: (_, record) => (
        <div>{record.member?.phone ? record.member?.phone : "-"}</div>
      ),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (_, record) => <div>{`${record.note ? record.note : "-"}`}</div>,
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "createdAt",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      defaultSortOrder: "descend",
      render: (_, record) => (
        <div>{`${
          record.createdAt
            ? dayjs(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        }`}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.member?.email.localeCompare(b.member?.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (_, record) => <div>{`${record.member?.email}`}</div>,
      onFilter: (value, record) =>
        record.member?.email
          ? record.member?.email
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, record) => (
        <div style={{ textTransform: "capitalize" }}>{record.status}</div>
      ),
    },
    {
      title: "Check - in",
      dataIndex: "checkIn",
      key: "checkIn",
      render: (_, record) => (
        <Checkbox
          checked={['unregister','confirmed'].includes(record.status)}
          disabled={
            record.status === "confirmed" || record.status === "canceled" || record.status === "unregister" || record.status === "cancelled"
          }
          onChange={() => handleConfirm(record)}
          className={(record.status === "canceled" || record.status === "cancelled" ) && "attendee-canceled"}
        ></Checkbox>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className="button-group">
          {(record.status === "attended" || record.status === "waiting") && (
            <button
              class="btn btn-danger btn-sm ml-5"
              type="primary"
              danger
              onClick={() => handleDelete(record)}
            >
              <DeleteOutlined /> Delete
            </button>

            // <button class="btn btn-danger btn-sm">
            //   <DeleteOutlined /> Delete
            // </button>
          )}
        </div>
      ),
    },
  ];
  const totalAttended = reportAttended && Number(reportAttended[0]?.total) + Number(reportAttended[1]?.total)

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <CSVLink
          filename={`${event?.title}_${moment(new Date()).format(
            "YYYYMMDD"
          )}.csv`}
          data={attendances}
          className="btn btn-secondary float-end mt-n1  ml-5"
        >
          <DownloadOutlined /> Export CSV
        </CSVLink>

        <Button
          type="primary"
          className="btn btn-success float-end mt-n1  ml-5"
          onClick={() => setCameraActive(true)}
        >
          <QrcodeOutlined /> SCAN QR
        </Button>

        <Link
          to={`/events/${id}/add-member`}
          className="btn btn-primary float-end mt-n1 ml-5"
        >
          <i class="fas fa-plus"></i>
          <PlusOutlined /> Add Member
        </Link>

        <Button
          style={{backgroundColor : '#fff'}}
          type='link'
          className="btn float-end mt-n1  ml-5"
          onClick={fetchData}
        >
          
           <ReloadOutlined />  Reload
        </Button>
        
        <h1 className="h3 mb-3">{event?.title}</h1>

        <Modal
          open={cameraActive}
          onCancel={() => setCameraActive(false)}
          footer=""
          zIndex={1002}
          title={
            <>
              <div>QR Code Scanner</div>
              <Divider />
            </>
          }
        >
          <div className="pt-3">
            <div className="align-center" align="center">
              <div style={{ width: "350px", marginBottom: "10px" }}>
                <QrScanner
                  scanDelay={delay}
                  onDecode={(result) => handleScan(result)}
                  onError={(error) => console.log(error)}
                />

                <br />
              </div>
              <div className="align-right">
                <Button
                  shape="round"
                  onClick={() => setCameraActive(false)}
                  icon={<CloseOutlined />}
                  size={"large"}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {showReport && (
                  <Row gutter={15}>
                    <Col span={12}>
                      <div className="information-wrap attended-information-wrap">
                        <div className="title">Attended information </div>
                        <Row align={"middle"} style={{ height: "100%" }} gutter={20}>
                          <Col span={12}>
                            {reportAttended && (
                              <DonutChart
                                type="attendance"
                                data={reportAttended}
                                color={colorAttended}
                                total={totalAttended}
                              />
                            )}
                          </Col>
                          <Col span={12}>
                            <div className="total-box">
                              <div className="title">Total Attendance</div>
                              <div className="number">
                                <span>{(+reportAttended?.[0].total) + (+reportAttended?.[1].total)}</span>/
                                {event?.capacity}
                              </div>
                            </div>
                            <Row gutter={[15]}>
                              {reportAttended.map((data, index) => (
                                <Col span={12}>
                                  <div className="total-sub-box box-purple"  
                                  style={{
                                      boxShadow: `0px 2px 4px 0px ${colorAttended[index]}`,
                                    }}>
                                    <div className="title text-overflow">{data.name}</div>
                                    <div className="number">{data.total}</div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="information-wrap attended-information-wrap">
                        <div className="title">Booking information </div>

                        <Row align={"middle"} gutter={10}>
                          <Col span={12}>
                            {reportBooking && (
                              <DonutChart
                                type="booking"
                                data={reportBooking}
                                color={colorBooking}
                                total={totalBooking}
                              />
                            )}
                          </Col>
                          <Col span={12}>
                            <div className="total-box"
                             style={{
                              boxShadow: `0px 0px 4px 0px #0072F9`,
                            }}>
                              <div className="title">Total Booking</div>
                              <div className="number">
                                <span style={{color: "#378CE7"}}>{totalBooking}</span>/{event?.capacity}
                              </div>
                            </div>
                            <Row gutter={[15, 15]}>
                              {reportBooking.map((data, index) => (
                                <Col span={12}>
                                  <div
                                    className="total-sub-box total-sub-sm-box box-purple"
                                    style={{
                                      boxShadow: `0px 2px 4px 0px ${colorBooking[index]}`,
                                    }}
                                  >
                                    <div className="title text-overflow">{data.name}</div>
                                    <div className="number">{data.total}</div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}

                <div className="card-header">
                  <h5 className="card-title mb-0">Attendee List</h5>
                </div>
                <Table
                  dataSource={event?.attendances}
                  columns={columns}
                  className="attendee-table"
                  rowKey="id"
                  size="middle"
                  scroll={{
                    x: "calc(700px + 50%)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Attendees;
