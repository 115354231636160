import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Form,
  Upload,
  Input,
  Button,
  Radio,
  message,
  DatePicker,
  InputNumber,
  Row,
  Col,
} from 'antd';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';
import { createEvent } from '../../api';
import EventLivePreview from '../../components/events/EventLivePreview';
import './../../App.css';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import {convertToGMTPlus7} from '../../until/date'

const { RangePicker } = DatePicker;

const Create = () => {
  const navigate = useNavigate();
  const [loading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [hostFileList, setHostFileList] = useState([]);

  const handleHostsChange =
    (hostNumber) =>
    ({ fileList }) => {
      const updatedFile = fileList[fileList.length - 1];
      setHostFileList((prevList) => {
        const newList = [...prevList];

        newList[hostNumber - 1] = {
          uid: updatedFile.uid || `-${hostNumber}`,
          name: updatedFile.name || `Image ${hostNumber}.png`,
          status: 'done',
          url: updatedFile.response
            ? updatedFile.response.url
            : updatedFile.url,
        };
        formik.setFieldValue(
          `hosts[${hostNumber - 1}].image`,
          updatedFile.response ? updatedFile.response.url : updatedFile.url
        );
        return newList;
      });
    };

  const renderHost = (hostNumber) => (
    <Form.Item key={hostNumber} label={`Speaker ${hostNumber}`}>
      <Row gutter={16} align="top">
        <Col xs={7} sm={5}>
          <Upload
            accept=".jpg,.png"
            multiple={false}
            action={`${process.env.REACT_APP_API_URL}/upload`}
            listType="picture-circle"
            fileList={
              hostFileList[hostNumber - 1]
                ? [hostFileList[hostNumber - 1]]
                : null
            }
            onChange={handleHostsChange(hostNumber)}
          >
            {hostFileList[hostNumber - 1] ? null : uploadButton}
          </Upload>
        </Col>
        <Col xs={17} sm={19}>
          <Row align="middle" style={{ marginBottom: 16, marginTop: 8 }}>
            <Col xs={6}>Name:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].name`}
                onChange={formik.handleChange}
                value={formik.values.hosts[hostNumber - 1].name}
              />
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={6}>Job Title:</Col>
            <Col xs={18}>
              <Input
                name={`hosts[${hostNumber - 1}].position`}
                onChange={formik.handleChange}
                value={formik.values.hosts[hostNumber - 1].position}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  );

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      requirement: '',
      images: [{ url: '' }],
      venue: '',
      capacity: 0,
      openFrom: '',
      openTo: '',
      registrationFrom: '',
      registrationTo: '',
      isPrivate: '',
      isPaid: '',
      paidAmount: 0,
      hosts: Array(5).fill({ image: {}, name: '', position: '' }),
      isFromAdmin: true,
      memberId: process.env.REACT_APP_ADMIN_ID,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      openFrom: Yup.string()
        .required('Open from is required')
        .matches(
          /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/,
          'Open from must be in the format YYYY-MM-DD HH:mm'
        ),
      openTo: Yup.string()
        .required('Open to is required')
        .matches(
          /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/,
          'Open to must be in the format YYYY-MM-DD HH:mm'
        ),

      registrationFrom: Yup.date().required('Registration from is required'),
      registrationTo: Yup.date().required('Registration to is required'),
      venue: Yup.string().required('Venue is required'),
      images: Yup.array()
        .of(
          Yup.object().shape({
            url: Yup.string().required('An image URL is required'),
          })
        )
        .required('At least one image is required'),
    }),
    onSubmit: async (values) => {
      values.openFrom = values?.openFrom? convertToGMTPlus7(values.openFrom): null
      values.openTo = values?.openTo? convertToGMTPlus7(values.openTo): null
      values.registrationFrom = values?.registrationFrom? convertToGMTPlus7(values.registrationFrom): null
      values.registrationTo = values?.registrationTo? convertToGMTPlus7(values.registrationTo): null
      values.hosts = values.hosts.filter((host) => {
        return (
          (host.name !== null && host.name !== '') ||
          (host.image !== null && host.image !== '')
        );
      });

      values.hosts = values.hosts.length > 0 ? values.hosts : [];
      try {
        const response = await createEvent(values);
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Event Created',
            text: 'The event has been created successfully.',
          });
          navigate('/events');
        }
      } catch (error) {
        // Handle error response
      }
    },
  });

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length <= 5) {
      setFileList(newFileList);
    } else {
      message.error('You can only upload up to 5 files!');
    }

    setFileList(newFileList);

    formik.setFieldValue(
      'images',
      newFileList
        .map((item) => {
          if (item.response && item.response.url) {
            return item.response;
          }
          return null;
        })
        .filter(Boolean)
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    formik.validateForm();
  }, [
    formik.values.openFrom,
    formik.values.openTo,
    formik.values.registrationFrom,
    formik.values.registrationTo,
  ]);

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Event</h1>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Create From</h5>
              </div>
              <div className="card-body">
                <Form
                  {...{
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                  }}
                >
                  <Form.Item
                    label="Cover"
                    validateStatus={formik.errors.images ? 'error' : ''}
                    help={
                      formik.errors.images &&
                      formik.errors.images.map((err, index) => (
                        <div key={index}>{err.url}</div>
                      ))
                    }
                  >
                    <Upload
                      accept=".jpg,.png"
                      multiple={false}
                      action={`${process.env.REACT_APP_API_URL}/upload`}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 4 ? null : uploadButton}
                    </Upload>
                  </Form.Item>

                  <Form.Item
                    label="Title"
                    validateStatus={formik.errors.title ? 'error' : ''}
                    help={formik.errors.title}
                  >
                    <Input
                      name="title"
                      placeholder="eg: Leveraging Advanced Technologies to Propel Your Business Forward"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Description"
                    label="Description"
                    validateStatus={formik.errors.description ? 'error' : ''}
                    help={formik.errors.description}
                  >
                    <Input.TextArea
                      name="description"
                      showCount
                      maxLength={2000}
                      rows={8}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Requirement"
                    validateStatus={formik.errors.requirement ? 'error' : ''}
                    help={formik.errors.requirement}
                  >
                    <Input.TextArea
                      name="requirement"
                      showCount
                      maxLength={2000}
                      rows={8}
                      onChange={formik.handleChange}
                      value={formik.values.requirement}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Event Date"
                    validateStatus={
                      formik.errors.openFrom || formik.errors.openTo
                        ? 'error'
                        : ''
                    }
                    help={
                      formik.errors.openFrom ? (
                        <span className="error-message">
                          {formik.errors.openFrom}
                        </span>
                      ) : formik.errors.openTo ? (
                        <span className="error-message">
                          {formik.errors.openTo}
                        </span>
                      ) : null
                    }
                  >
                    <RangePicker
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={(dates, dateStrings) => {
                        if (dateStrings && dateStrings.length >= 2) {
                          formik.setFieldValue('openFrom', dateStrings[0]);
                          formik.setFieldValue('openTo', dateStrings[1]);
                        }
                        formik.setFieldTouched('openFrom', true, false);
                        formik.setFieldTouched('openTo', true, false);
                      }}
                      onOk={(dates, dateStrings) => {
                        if (dateStrings && dateStrings.length >= 2) {
                          formik.setFieldValue('openFrom', dateStrings[0]);
                          formik.setFieldValue('openTo', dateStrings[1]);
                        }
                        formik.setFieldTouched('openFrom', true, false);
                        formik.setFieldTouched('openTo', true, false);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Registration Date"
                    validateStatus={
                      formik.errors.registrationDate ? 'error' : ''
                    }
                    help={
                      formik.errors.registrationFrom ? (
                        <span className="error-message">
                          {formik.errors.registrationFrom}
                        </span>
                      ) : null
                    }
                  >
                    <RangePicker
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={(dates, dateStrings) => {
                        if (dateStrings && dateStrings.length >= 2) {
                          formik.setFieldValue(
                            'registrationFrom',
                            dateStrings[0]
                          );
                          formik.setFieldValue(
                            'registrationTo',
                            dateStrings[1]
                          );
                        }
                        formik.setFieldTouched('registrationFrom', true, false);
                        formik.setFieldTouched('registrationTo', true, false);
                      }}
                      onOk={(dates, dateStrings) => {
                        if (dateStrings && dateStrings.length >= 2) {
                          formik.setFieldValue(
                            'registrationFrom',
                            dateStrings[0]
                          );
                          formik.setFieldValue(
                            'registrationTo',
                            dateStrings[1]
                          );
                        }
                        formik.setFieldTouched('registrationFrom', true, false);
                        formik.setFieldTouched('registrationTo', true, false);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Location"
                    validateStatus={formik.errors.venue ? 'error' : ''}
                    help={formik.errors.venue}
                  >
                    <Input
                      name="venue"
                      placeholder="eg: Sofitel Bangkok"
                      value={formik.values.venue}
                      onChange={(e) =>
                        formik.setFieldValue('venue', e.target.value)
                      }
                      help={formik.errors.venue}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Capacity"
                    validateStatus={formik.errors.capacity ? 'error' : ''}
                    help={formik.errors.capacity}
                  >
                    <InputNumber
                      name="capacity"
                      min={0}
                      max={10000}
                      defaultValue={formik.values.capacity}
                      onChange={(value) =>
                        formik.setFieldValue('capacity', value)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Type"
                    validateStatus={formik.errors.isPrivate ? 'error' : ''}
                    help={formik.errors.isPrivate}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        formik.setFieldValue(
                          'isPrivate',
                          e.target.value === 'private'
                        )
                      }
                      value={formik.values.isPrivate ? 'private' : 'public'}
                    >
                      <Radio value="public">Public</Radio>
                      <Radio value="private">Private</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Event fee"
                    validateStatus={formik.errors.isPaid ? 'error' : ''}
                    help={formik.errors.isPaid}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        formik.setFieldValue(
                          'isPaid',
                          e.target.value === 'paid'
                        )
                      }
                      value={formik.values.isPaid ? 'paid' : 'free'}
                    >
                      <Radio value="free">Free</Radio>
                      <Radio value="paid">Paid</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {formik.values.isPaid && (
                    <Form.Item
                      label="Paid Amount"
                      validateStatus={formik.errors.paidAmount ? 'error' : ''}
                      help={formik.errors.paidAmount}
                    >
                      <InputNumber
                        name="paidAmount"
                        min={0}
                        max={10000}
                        defaultValue={formik.values.paidAmount}
                        onChange={(value) =>
                          formik.setFieldValue('paidAmount', value)
                        }
                      />
                    </Form.Item>
                  )}

                  {Array.from({ length: 5 }, (_, index) =>
                    renderHost(index + 1)
                  )}

                  <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
                    <Button type="primary" onClick={formik.handleSubmit}>
                      Create
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          <EventLivePreview formik={formik} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Create;
