import AxiosInstance from "./axios";
import moment from 'moment'
import 'moment-timezone' 
import { ETimezone } from "../until/date";
moment.tz.setDefault(ETimezone.BANGKOK)
export async function getMeetingRoom(value) {
  const response = await AxiosInstance.get(`/rooms/meeting-room?${value}`);
  if (value.includes("month")) {
    const resFormatDate = response.data.map((item) => ({
      ...item,
      start: forceToBangkokTimezone(item.start),
      end: forceToBangkokTimezone(item.end),
      bookTimes: item.bookTimes.map((time) => ({
        ...time,
        start: forceToBangkokTimezone(time.start),
        end: forceToBangkokTimezone(time.end)
      })),
    }));

    return resFormatDate;
  } else {
    const resFormatDate = response.data.map((item) => ({
      ...item,
      start: forceToBangkokTimezone(item.start),
      end: forceToBangkokTimezone(item.end),
    }));
    return resFormatDate;
  }
}

export async function getMeetingRoomById(id) {
  const response = await AxiosInstance.get(`/rooms/meeting-room/${id}`);
  return response;
}

export async function getRoomTemplate(value) {
  const response = await AxiosInstance.get(`/rooms/template?${value}`);
  return response.data;
}

export async function createBookingRoom(data) {
  const response = await AxiosInstance.post(`/rooms/booking`, data);
  return response;
}

export async function updateBookingRoom(data, id) {
  const response = await AxiosInstance.put(`/rooms/meeting-room/${id}`, data);
  return response;
}

export async function updateBookingRoomStatus(status, id) {
  const response = await AxiosInstance.put(
    `/rooms/meeting-room/status/${id}`,
    status
  );
  return response;
}

export async function updateBookingRoomNote(note, id) {
  const response = await AxiosInstance.put(
    `/rooms/meeting-room/note/${id}`,
    note
  );
  return response;
}


function forceToBangkokTimezone(DateTime){
  const bangkokTime = new Date(moment(DateTime).tz(ETimezone.BANGKOK))
  return bangkokTime
}