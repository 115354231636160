import AxiosInstance from "./axios";

export async function getMemberCheckIns() {
  const checkIns = await AxiosInstance.get(`/member-check-ins`);
  return checkIns.data;
}

export async function getMemberCheckIn(id) {
  const checkIn = await AxiosInstance.get(`/member-check-ins/${id}/last`);
  return checkIn.data;
}
