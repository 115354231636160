import React from "react";
import { Column } from "@ant-design/plots";
import { height, width } from "@fortawesome/free-solid-svg-icons/fa0";
const BarChart = () => {
  const config = {
    data: {
      type: "fetch",
      value:
        "https://gw.alipayobjects.com/os/antfincdn/iPY8JFnxdb/dodge-padding.json",
    },
    xField: "月份",
    yField: "月均降雨量",
    colorField: "name",
    group: true,
    height: 200,
    style: {
      insetLeft: 2,
      insetRight: 3,
    },
  };
  return <Column {...config} />;
};

export default BarChart;
