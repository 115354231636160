import React from "react";
import dayjs from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "moment-timezone";
import moment from "moment";
import { ETimezone } from "../../until/date";

moment.tz.setDefault(ETimezone.BANGKOK);

const ModalTimeSlot = ({
  moveModal,
  isModalBooking,
  closeTimeList,
  eventSelect,
  editBooking,
}) => {
  return (
    <Modal
      className={`timeList time-slot ${moveModal}`}
      open={isModalBooking}
      width={320}
      onCancel={closeTimeList}
      closeIcon={false}
      footer=""
      title={
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column text-xl ps-2 pe-3 me-3 border-end border-1 text-center uppercase">
            <span>{moment(eventSelect?.start).format("ddd")}</span>
            <strong>{moment(eventSelect?.start).format("D")}</strong>
          </div>
          <div className="event-title">{eventSelect?.title}</div>
          <div className="ms-auto controlBox">
            <a className="btn btnModal" onClick={closeTimeList}>
              <CloseOutlined />
            </a>
          </div>
        </div>
      }
    >
      <div className="bookingList">
        {eventSelect?.bookTimes?.map((e, i) => {
          return (
            <div
              key={e.id + i}
              onClick={() => editBooking(e.id)}
              className={`bookingListItem text-black cursor-pointer`}
            >
              <span
                className={`${e.status === "Canceled" && "booking-cancel"}`}
              >
                {moment(e.start).format("HH:mm")} -{" "}
                {moment(e.end).format("HH:mm")}{" "}
                <strong className="ms-2">{e.title ?? ""}</strong>
                <span className="ms-2 text-secondary">
                  by {e.organizer.firstName ?? ""}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default ModalTimeSlot;
