import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AxiosInstance.interceptors.request.use(async (config) => {
  const localStorageToken = await localStorage.getItem("token");
  const accessToken = localStorageToken;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.log("go to logout page");
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
