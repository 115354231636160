import AxiosInstance from "./axios";

export async function getHouses(currentPage, pageSize) {
  const response = await AxiosInstance.get(
    `/houses?page=${currentPage}&limit=${pageSize}`
  );
  return response.data;
}

export async function getHouse(id) {
  const response = await AxiosInstance.get(`/houses/${id}`);
  console.log(response.data.statusCode);
  return response;
}

export async function getHouseMembers(id) {
  const response = await AxiosInstance.get(`/houses/${id}/member-lists`);

  return response;
}

export async function createHouse(formData) {
  const response = await AxiosInstance.post(`/houses`, formData);

  return response;
}

export async function updateHouse(id, formData) {
  const response = await AxiosInstance.patch(`/houses/${id}`, formData);
  return response;
}

export async function deleteHouse(id) {
  const response = await AxiosInstance.delete(`/houses/${id}`);
  return response;
}
