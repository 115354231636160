import {
  faCalendar,
  faChartLine,
  faQuestion,
  faUsers,
  faNewspaper,
  faSignInAlt,
  faSignOutAlt,
  faGift,
  faBook,
  faHouseUser,
  faThumbtack,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = ({ isCollapsed }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isActive = (path) => currentPath.includes(path);
  return (
    <nav
      id="sidebar"
      className={`sidebar js-sidebar ${isCollapsed ? "collapsed" : ""}`}
    >
      <div className="sidebar-content js-simplebar">
        <Link to="/" className="sidebar-brand">
          <span className="align-middle">
            <img
              src="/assets/images/icon.png"
              className="sidebar-icon-image me-2"
              alt=""
            />
            H.O.W. Bangkok
          </span>
        </Link>

        <ul className="sidebar-nav">
          <li className="sidebar-header">Pages</li>

          <li
            className={`sidebar-item ${isActive("/dashboard") ? "active" : ""}`}
          >
            <Link to="/dashboard" className="sidebar-link">
              <FontAwesomeIcon icon={faChartLine} />
              <span className="align-middle">Dashboard</span>
            </Link>
          </li>

          <li
            className={`sidebar-item ${
              isActive("/meeting-rooms") ? "active" : ""
            }`}
          >
            <Link to="/meeting-rooms" className="sidebar-link">
              <FontAwesomeIcon icon={faDoorOpen} />
              <span className="align-middle">Meeting Rooms</span>
            </Link>
          </li>

          <li className={`sidebar-item ${currentPath === "/" ? "active" : ""}`}>
            <Link to="/" className="sidebar-link">
              <FontAwesomeIcon icon={faUsers} />
              <span className="align-middle">Members</span>
            </Link>
          </li>

          <li
            className={`sidebar-item ${isActive("/articles") ? "active" : ""}`}
          >
            <Link to="/articles" className="sidebar-link">
              <FontAwesomeIcon icon={faNewspaper} />
              <span className="align-middle">Articles</span>
            </Link>
          </li>

          <li className={`sidebar-item ${isActive("/events") ? "active" : ""}`}>
            <Link to="/events" className="sidebar-link">
              <FontAwesomeIcon icon={faCalendar} />
              <span className="align-middle">Events</span>
            </Link>
          </li>

          <li className={`sidebar-item ${isActive("/houses") ? "active" : ""}`}>
            <Link to="/houses" className="sidebar-link">
              <FontAwesomeIcon icon={faHouseUser} />
              <span className="align-middle">Houses</span>
            </Link>
          </li>

          <li
            className={`sidebar-item ${
              isActive("/privileges") ? "active" : ""
            }`}
          >
            <Link to="/privileges" className="sidebar-link">
              <FontAwesomeIcon icon={faGift} />
              <span className="align-middle">Privileges</span>
            </Link>
          </li>

          <li
            className={`sidebar-item ${isActive("/check-in") ? "active" : ""}`}
          >
            <Link to="/check-in" className="sidebar-link">
              <FontAwesomeIcon icon={faSignInAlt} />
              <span className="align-middle">Check-in</span>
            </Link>
          </li>

          <li
            className={`sidebar-item ${isActive("/check-out") ? "active" : ""}`}
          >
            <Link to="/check-out" className="sidebar-link">
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span className="align-middle">Check-out</span>
            </Link>
          </li>

          <li className={`sidebar-item ${isActive("/faqs") ? "active" : ""}`}>
            <Link to="/faqs" className="sidebar-link">
              <FontAwesomeIcon icon={faQuestion} />
              <span className="align-middle">FAQs</span>
            </Link>
          </li>

          <li className={`sidebar-item ${isActive("/logs") ? "active" : ""}`}>
            <Link to="/logs" className="sidebar-link">
              <FontAwesomeIcon icon={faBook} />
              <span className="align-middle">Logs</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
