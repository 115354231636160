import AxiosInstance from "./axios";

export async function getArticles(currentPage, pageSize) {
  const response = await AxiosInstance.get(`/articles?page=${currentPage}&limit=${pageSize}`);
  return response.data;
}

export async function getArticle(id) {
  const response = await AxiosInstance.get(`/articles/${id}`);
  return response;
}

export async function createArticle(formData) {
  const response = await AxiosInstance.post(`/articles`, formData);
  return response;
}

export async function updateArticle(id, formData) {
  const response = await AxiosInstance.patch(`/articles/${id}`, formData);
  return response;
}


export async function deleteArticle(id) {
  const response = await AxiosInstance.delete(`/articles/${id}`);
  return response;
}
