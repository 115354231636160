import React from "react";
import LoginLayout from '../components/layouts/LoginLayout/LoginLayout';

const Login = () => {

  return (
    <LoginLayout />
  );
};

export default Login;
