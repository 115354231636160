import AxiosInstance from "./axios";

export async function deleteEventAttendance(id) {
  const response = await AxiosInstance.delete(`/event-attendance/${id}`);
  return response;
}

export async function confirmEventAttendance(id) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await AxiosInstance.patch(
    `/event-attendance/${id}/confirmed`,
    {},
    config
  );
  return response;
}

export async function checkInByQRScan(id, memberId) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await AxiosInstance.patch(
    `/event-attendance/${id}/member/${memberId}/qr-confirm`,
    {},
    config
  );
  return response;
}

export async function addMemberToEvent(eventId, memberId) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await AxiosInstance.post(
    `/event-attendance/add-member`,
    {
      eventId,
      memberId,
    },
    config
  );
  return response;
}

export async function addMemberWalkToEvent(eventId, memberId) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await AxiosInstance.post(
    `/event-attendance/add-walk-in`,
    {
      eventId,
      memberId,
    },
    config
  );
  return response;
}

export async function getEventReport(eventId) {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await AxiosInstance.get(
    `event-attendance/report/${eventId}`,
    config
  );
  return response;
}
