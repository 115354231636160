import React from "react";
import { Pie } from "@ant-design/plots";
const PieChart = () => {
  const config = {
    data: [
      { name: "Chrome", value: 27 },
      { name: "Firefox", value: 25 },
      { name: "IE", value: 18 },
    ],
    height: 200,
    percent: true,
    angleField: "value",
    colorField: "name",
    innerRadius: 0.4,
    label: {
      text: "value",
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "bottom",
        rowPadding: 5,
      },
    },
  };
  return <Pie {...config} />;
};
export default PieChart;
