import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AdminLayout from './../components/layouts/AdminLayout/AdminLayout';
import Table from 'antd/lib/table';
import Switch from 'antd/lib/switch';
import Badge from 'antd/lib/badge';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import { getMemberCheckIn, checkOutFromHow } from './../api';
import {
  CloseOutlined,
  ExportOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { QrScanner } from '@yudiel/react-qr-scanner';
import {
  getMembers,
  getMemberTiers,
  toggleMemberActiveStatus,
  toggleMemberLockedStatus,
  updateMemberTier,
  getMemberCheckIns,
} from './../api';
import './../App.css';
import Swal from 'sweetalert2';
import './../components/custom.css';

const CheckOut = () => {
  const delay = 5000;
  const [cameraActive, setCameraActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [checkIns, setCheckIns] = useState([]);

  const fetchMemberCheckIns = async () => {
    const data = await getMemberCheckIns();
    setCheckIns(data);
  };

  const checkOut = async (memberId, name) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to check out, ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      const checkOut = await checkOutFromHow(memberId);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: checkOut.data.message,
      });
      fetchMemberCheckIns();
    } else if (result.isDismissed) {
      Swal.fire('Action Cancelled', `You remain checked in, ${name}.`, 'info');
    }
  };

  const handleScan = async (memberId) => {
    if (isProcessing) {
      return;
    }

    if (memberId && setCameraActive) {
      setIsProcessing(true);

      try {
        const member = await getMemberCheckIn(memberId);
        if (member.checkOutTime !== null) {
          Swal.fire({
            icon: 'error',
            title: 'Checkout Failed',
            text: 'No matching check-in found.',
          });
        }

        const checkOut = await checkOutFromHow(memberId);

        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: checkOut.data.message,
        });

        fetchMemberCheckIns();
      } catch (error) {
        if (error.response) {
          let errorMessage = error.response.data.message;

          if (errorMessage === 'Validation failed (uuid is expected)') {
            errorMessage = 'Invalid QR Code';
          }

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMessage,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          });
          console.error('Error Message:', error.message);
        }
      }

      setIsProcessing(false);
      setCameraActive(false);
    }
  };

  useEffect(() => {
    fetchMemberCheckIns();
  }, []);

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => (
        <div>
          {record.member && record.member.title ? record.member.title : ''}{' '}
          {record.member && record.member.firstName
            ? record.member.firstName
            : ''}
          {record.member && record.member.lastName
            ? record.member.lastName
            : ''}
        </div>
      ),
    },
    {
      title: 'Nick Name',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (_, record) => (
        <div>
          {record.member && record.member.nickname
            ? record.member.nickname
            : ''}
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, record) => (
        <div>
          {record.member && record.member.phone ? record.member.phone : ''}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => (
        <div>
          {record.member && record.member.email ? record.member.email : ''}
        </div>
      ),
    },

    {
      title: 'Check In At',
      dataIndex: 'checkInAt',
      key: 'checkInAt',
      render: (_, record) => (
        <div>{dayjs(record.checkInTime).format('YYYY-MM-DD HH:mm:ss')}</div>
      ),
    },
    {
      title: 'Check out',
      dataIndex: 'checkOut',
      key: 'checkOut',
      render: (_, record) => (
        <Button
          className="check-out-icon"
          type="primary"
          danger
          onClick={() => checkOut(record.member.id, record.member.nickname)}
        >
          <ExportOutlined />
          Check-out
        </Button>
      ),
    },
  ];

  const handleActiveSwitchChange = async (checked, record) => {
    const update = await toggleMemberActiveStatus(record.id, checked);
    if (update) {
      fetchMemberCheckIns();
      message.success('Member active status updated!');
    }
  };

  const handleLockedSwitchChange = async (checked, record) => {
    const update = await toggleMemberLockedStatus(record.id, checked);
    if (update) {
      fetchMemberCheckIns();
      message.success('Member locked status updated!');
    }
  };

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1 className="h3 mb-3">Check out</h1>
          <QrcodeOutlined
            style={{ fontSize: '30px' }}
            onClick={() => setCameraActive(true)}
          />
        </div>
        <div className="row">
          <div className="col-12">
            {cameraActive ? (
              <div className="align-center" align="center">
                <div style={{ width: '350px', marginBottom: '10px' }}>
                  <QrScanner
                    constraints={{
                      facingMode: {
                        exact: 'environment',
                      },
                    }}
                    scanDelay={delay}
                    onDecode={(result) => handleScan(result)}
                    onError={(error) => console.log(error?.message)}
                  />
                  <Button
                    type="primary"
                    shape="round"
                    className="close-camera-icon"
                    onClick={() => setCameraActive(false)}
                    icon={<CloseOutlined />}
                    size={'large'}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Member In HOW</h5>
                </div>
                <div className="card-body">
                  <Table
                    dataSource={
                      checkIns
                        ? checkIns.map((checkIn) => ({
                            ...checkIn,
                            key: checkIn.id,
                          }))
                        : []
                    }
                    columns={columns}
                    scroll={{
                      x: 'calc(700px + 50%)',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CheckOut;
