import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import Input from 'antd/lib/input';
import Space from 'antd/lib/space';
import {
  SearchOutlined,
  CheckSquareFilled,
  CloseOutlined,
} from '@ant-design/icons';
import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';
import { getMembersWithoutAttendees, addMemberToEvent } from '../../api';
import './../../App.css';

const Members = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState();
  const [attendances, setAttendances] = useState([]);
  const [cameraActive, setCameraActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const delay = 5000;

  const formatDataForCSV = (attendances) => {
    return attendances.map((attendance) => ({
      Name: `${attendance.member.title} ${attendance.member.firstName} ${attendance.member.lastName}`,
      Email: attendance.member.email,
      Phone: `'${attendance.member.phone}`,
      Company: attendance.member.company,
      'Join Date': dayjs(attendance.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      note: attendance.note,
    }));
  };
  const fetchEvent = useCallback(
    async (id) => {
      try {
        const eventResult = await getMembersWithoutAttendees(id);

        setEvent(eventResult.data);
        const formattedData = formatDataForCSV(eventResult.data.attendances);

        setAttendances(formattedData);
      } catch (error) {
        console.error(error);
        // navigate('/events');
      }
    },
    [navigate]
  );

  const addToEvent = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to add "${record.title} ${record.firstName} ${record.lastName}" as an attendee to the event?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await addMemberToEvent(id, record.id);

          if (response.status === 201 && response.data.status !== 'error') {
            Swal.fire(
              'Confirmed!',
              'This Member has been added to the Event.',
              'success'
            ).then((result) => {
              if (result.value) {
                navigate(`/events/${id}/attendees`);
              }
            });
            fetchEvent(id);
          } else {
            Swal.fire('Error', response.data.message, 'error');
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while adding the member to the event.',
            'error'
          );
        }
      }
    });
  };

  useEffect(() => {
    fetchEvent(id);
  }, [fetchEvent, id]);

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (_, record) =>
        record.avatar ? (
          <Image src={record.avatar} width={100} height={56} />
        ) : (
          <Image src="/assets/images/events/default-cover.png" width={80} />
        ),
    },
    {
      title: 'Full Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => {
        const nameA =
          `${a.member.title} ${a.member.firstName} ${a.member.lastName}`.toLowerCase();
        const nameB =
          `${b.member.title} ${b.member.firstName} ${b.member.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Full Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        const fullName =
          `${record.title} ${record.firstName} ${record.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (_, record) => (
        <div>
          {record.title} {record.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) =>
        (a.member.phone || '').localeCompare(b.member.phone || ''),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Phone`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.phone
          ? record.phone.toString().toLowerCase().includes(value.toLowerCase())
          : '',
      render: (_, record) => <div>{record.phone ? record.phone : '-'}</div>,
    },
    {
      title: 'Join Date',
      dataIndex: 'joinDate',
      key: 'createdAt',
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <div>{`${
          record.createdAt
            ? dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : '-'
        }`}</div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.member?.email.localeCompare(b.member?.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (_, record) => <div>{`${record.email}`}</div>,
      onFilter: (value, record) =>
        record.email
          ? record.email.toString().toLowerCase().includes(value.toLowerCase())
          : '-',
    },

    {
      title: 'Action',
      dataIndex: 'locked',
      key: 'locked',
      width: 120,
      render: (_, record) => (
        <div className="button-group">
          <Button
            className="custom-button"
            size="small"
            type="primary"
            danger
            icon={<CheckSquareFilled />}
            style={{ backgroundColor: '#28a745', borderColor: '#28a745' }}
            onClick={() => addToEvent(record)}
          >
            Add to event
          </Button>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <h1 className="h3 mb-3">Events : {event?.title}</h1>
        {cameraActive && (
          <div className="align-center" align="center">
            <div style={{ width: '350px', marginBottom: '10px' }}>
              <br />
              <div className="text-center">
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => setCameraActive(false)}
                  icon={<CloseOutlined />}
                  size={'large'}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Member Lists</h5>
              </div>
              <div className="card-body">
                <Table
                  dataSource={event?.members}
                  columns={columns}
                  rowKey="id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Members;
