import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useParams, useNavigate } from 'react-router-dom';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Image from 'antd/lib/image';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import Input from 'antd/lib/input';
import Space from 'antd/lib/space';

import {
  SearchOutlined,
  CheckSquareFilled,
  QrcodeOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import AdminLayout from '../../components/layouts/AdminLayout/AdminLayout';
import { CSVLink } from 'react-csv';

import {
  getEventWithAttendees,
  deleteEventAttendance,
  confirmEventAttendance,
  checkInByQRScan,
  getHouseMembers,
} from '../../api';
import './../../App.css';

const Members = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState();
  const [attendances, setAttendances] = useState([]);
  const [cameraActive, setCameraActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const delay = 5000;

  const formatDataForCSV = (attendances) => {
    return attendances.map((attendance) => ({
      Name: `${attendance.member.title} ${attendance.member.firstName} ${attendance.member.lastName}`,
      Email: attendance.member.email,
      Phone: `'${attendance.member.phone}`,
      Company: attendance.member.company,
      'Join Date': dayjs(attendance.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      note: attendance.note,
      status: attendance.status,
    }));
  };
  const fetchEvent = useCallback(
    async (id) => {
      try {
        console.log(id);
        const eventResult = await getHouseMembers(id);

        setEvent(eventResult.data);
        const formattedData = formatDataForCSV(eventResult.data.attendances);

        setAttendances(formattedData);
      } catch (error) {
        console.error(error);
        // navigate('/events');
      }
    },
    [navigate]
  );

  const handleAddMemberToEvent = () => {
    navigate(`/events/${id}/add-member`);
  };

  const handleScan = async (memberId) => {
    if (isProcessing) {
      return;
    }
    if (memberId && setCameraActive) {
      setIsProcessing(true);
      try {
        const response = await checkInByQRScan(id, memberId);
        console.log(id, memberId);
        console.log('Response data ===>', response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Checked in successfully!',
        });
        fetchEvent(id);
      } catch (error) {
        if (error.response) {
          console.error('Data:', error.response.data);
          console.error('Status:', error.response.status);
          console.error('Headers:', error.response.headers);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          });
          console.error('Error Message:', error.message);
        }
      }
      setIsProcessing(false);
      setCameraActive(false);
    }
  };

  const handleConfirm = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Can you confirm that "${record.member.title}. ${record.member.firstName} ${record.member.lastName}" is present at the event?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await confirmEventAttendance(record.id);
          //     // Check the response code here
          if (response.status === 200) {
            Swal.fire(
              'Confirmed!',
              'This Member has been confirmed.',
              'success'
            );
            fetchEvent(id);
          } else {
            Swal.fire(
              'Error',
              'An error occurred while deleting the item.',
              'error'
            );
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while deleting the item.',
            'error'
          );
        }
      }
    });
  };
  const handleDelete = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteEventAttendance(record.id);
          // Check the response code here
          if (response.status === 200) {
            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
            fetchEvent(id);
          } else {
            Swal.fire(
              'Error',
              'An error occurred while deleting the item.',
              'error'
            );
          }
        } catch (error) {
          Swal.fire(
            'Error',
            'An error occurred while deleting the item.',
            'error'
          );
        }
      }
    });
  };

  useEffect(() => {
    fetchEvent(id);
  }, [fetchEvent, id]);

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (_, record) =>
        record.member?.avatar ? (
          <Image src={record.member.avatar} width={100} height={56} />
        ) : (
          <Image src="/assets/images/events/default-cover.png" width={80} />
        ),
    },
    {
      title: 'Full Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => {
        const nameA =
          `${a.member.title} ${a.member.firstName} ${a.member.lastName}`.toLowerCase();
        const nameB =
          `${b.member.title} ${b.member.firstName} ${b.member.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Full Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        const fullName =
          `${record.member.title} ${record.member.firstName} ${record.member.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (_, record) => (
        <div>
          {record.member?.title} {record.member?.firstName}{' '}
          {record.member?.lastName}
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) =>
        (a.member.phone || '').localeCompare(b.member.phone || ''),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Phone`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.member.phone
          ? record.member.phone
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : '',
      render: (_, record) => (
        <div>{record.member?.phone ? record.member?.phone : '-'}</div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.member?.email.localeCompare(b.member?.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => {clearFilters(); confirm();}} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (_, record) => <div>{`${record.member?.email}`}</div>,
      onFilter: (value, record) =>
        record.member?.email
          ? record.member?.email
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : '-',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="button-group">
          {record.status === 'attended' && (
            <button
              class="btn btn-success btn-sm"
              type="primary"
              danger
              icon={<CheckSquareFilled />}
              onClick={() => handleConfirm(record)}
            >
              <CheckSquareFilled /> Confirm
            </button>
          )}

          {(record.status === 'attended' || record.status === 'waiting') && (
            <button
              class="btn btn-danger btn-sm ml-5"
              type="primary"
              danger
              onClick={() => handleDelete(record)}
            >
              <DeleteOutlined /> Delete
            </button>

            // <button class="btn btn-danger btn-sm">
            //   <DeleteOutlined /> Delete
            // </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="container-fluid p-0">
        <CSVLink
          filename={`attendances-${event?.title}.csv`}
          data={attendances}
          className="btn btn-secondary float-end mt-n1  ml-5"
        >
          <DownloadOutlined /> Export CSV
        </CSVLink>

        <Button
          type="primary"
          className="btn btn-success float-end mt-n1  ml-5"
          onClick={() => setCameraActive(true)}
        >
          <QrcodeOutlined /> SCAN QR
        </Button>

        <Link
          to={`/events/${id}/add-member`}
          className="btn btn-primary float-end mt-n1"
        >
          <i class="fas fa-plus"></i>
          <PlusOutlined /> Add Member
        </Link>
        <h1 className="h3 mb-3">House : {event?.name}</h1>
        {cameraActive && (
          <div className="align-center" align="center">
            <div style={{ width: '350px', marginBottom: '10px' }}>
              <QrScanner
                constraints={{
                  facingMode: {
                    exact: 'environment',
                  },
                }}
                scanDelay={delay}
                onDecode={(result) => handleScan(result)}
                onError={(error) => console.log(error?.message)}
              />

              <br />
              <div className="text-center">
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => setCameraActive(false)}
                  icon={<CloseOutlined />}
                  size={'large'}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Members</h5>
              </div>
              <div className="card-body">
                <Table
                  dataSource={event?.members}
                  columns={columns}
                  rowKey="id"
                  size="middle"
                  scroll={{
                    x: 'calc(700px + 50%)',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Members;
