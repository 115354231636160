import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { googleAuthentication } from "./../../../api";

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID);
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("google-signin-btn"),
      {} /* customization options */
    );
  }, []);

  const handleCredentialResponse = async (response) => {
    const { credential } = response;
    const auth = await googleAuthentication(credential);

    localStorage.setItem(
      "token",
      auth?.token
    );

    if (auth && auth.token) {
      navigate("/");
    }
  };

  return (
    <main className="d-flex w-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Welcome back!</h1>
                <p className="lead">Sign in to your account to continue</p>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="m-sm-3">
                    {/*
                    <form>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                      </div>
                      <div>
                        <div className="form-check align-items-center">
                          <input
                            id="customControlInline"
                            type="checkbox"
                            className="form-check-input"
                            value="remember-me"
                            name="remember-me"
                            checked
                            readOnly
                          />

                          <label className="form-check-label text-small" htmlFor="customControlInline">
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="d-grid gap-2 mt-3">
                        <a href="/login" className="btn btn-lg btn-primary">
                          Sign in
                        </a>
                      </div>
                    </form>
                    <div className="line-text">
                      <span>Social Login</span>
                    </div>
                    */}
                    <center>
                      <div
                        id="google-signin-btn"
                        style={{ width: "100%" }}
                      ></div>
                    </center>
                  </div>
                </div>
              </div>
              {/* <div className="text-center mb-3">
                Don't have an account? <a href="pages-sign-up.html">Sign up</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminLayout;
